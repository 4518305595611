import React, { createContext, useContext, useState, ReactNode } from "react";
import { themes } from "./Themes"; // Importiere die Themes aus der Themes-Datei

// Typ für den Theme-Kontext
interface ThemeContextType {
  theme: typeof themes["newstandard"]; // Typisiere das aktuelle Theme basierend auf dem Standard-Theme
  switchTheme: (themeName: keyof typeof themes) => void; // Typisiere die Theme-Namen
}

// Erstelle den Theme-Kontext
export const ThemeContext = createContext<ThemeContextType>({
  theme: themes.newstandard, // Setze das Standard-Theme auf newstandard
  switchTheme: () => {}, // Platzhalter-Funktion
});

// Provider für den Kontext
export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Hole das gespeicherte Theme aus dem LocalStorage oder setze newstandard als Standard
  const savedTheme = localStorage.getItem("theme") as keyof typeof themes;
  const initialTheme = savedTheme && themes[savedTheme] ? themes[savedTheme] : themes.newstandard;

  const [theme, setTheme] = useState<typeof themes["newstandard"]>(initialTheme);

  const switchTheme = (themeName: keyof typeof themes) => {
    if (themes[themeName]) {
      setTheme(themes[themeName]); // Aktualisiere das Theme
      localStorage.setItem("theme", themeName); // Speichere das Theme in LocalStorage
    } else {
      console.warn(`Theme "${themeName}" not found`);
    }
  };

  return (
    <ThemeContext.Provider value={{ theme, switchTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

// Hook zum Verwenden des Themes
export const useTheme = () => useContext(ThemeContext);
