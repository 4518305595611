import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import LoginFormWithRouter from './LoginForm';
import ChatOptionsForm from './ChatOptionsForm';
import ChatInterfaceParent from './ChatInterfaceParent';
import AppContext from './AppContext';
import { BrowserRouter as Router } from "react-router-dom";
import { LoggerGlobal } from '@video/log-client';
import { ThemeProvider } from "./ThemeContext";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loginData: null,currentForm: "login",chatOptions: null,language: "auto"};

    const yourLogger = new LoggerGlobal();
    yourLogger.setOptions({
      host: "https://guppy.generflow.com",
      interval: 5000, // This refers to the interval in which your logs will be sent, 5000 === 5000 Milliseconds.
      level: "info" // Please refer to the list below for the available levels.
    });
  }

  sendLoginResult(loginData) {
    //alert("Received LoginData: " + JSON.stringify(loginData));
    this.setState({loginData: loginData,currentForm: "options"});
    if(typeof(window.require) === "function") {
      const electron = window.require('electron');
      let {ipcRenderer} = electron;
      if(window.screen.availHeight < 1000) {
        ipcRenderer.send('resize', 960, window.screen.availHeight);
      } else {
        ipcRenderer.send('resize', 960, 1000);
      }
    }
  }

  changeLanguage(newLang) {
    this.setState({language: newLang});
  }

  sendChatOptions(chatOptionsData) {
    this.setState({chatOptions: chatOptionsData,currentForm: "chat"});
    if(typeof(window.require) === "function") {
      const electron = window.require('electron');
      let {ipcRenderer} = electron;
      let myWindowWidth = 1380;
      if(window.screen.availWidth < 1380) {
        myWindowWidth = window.screen.availWidth;
      }
      if(window.screen.availHeight < 1000) {
        ipcRenderer.send('resize', myWindowWidth, window.screen.availHeight);
      } else {
        ipcRenderer.send('resize', myWindowWidth, 1000);
      }
    }
  }

  gotoOptions() {
    this.setState({currentForm: "options"});
    if(typeof(window.require) === "function") {
      const electron = window.require('electron');
      let {ipcRenderer} = electron;
      if(window.screen.availHeight < 1000) {
        ipcRenderer.send('resize', 960, window.screen.availHeight);
      } else {
        ipcRenderer.send('resize', 960, 1000);
      }
    }
  }

  exitChat(chatOptionsData) {
    this.setState({currentForm: "login",loginData: null});
    if(typeof(window.require) === "function") {
      const electron = window.require('electron');
      let {ipcRenderer} = electron;
      ipcRenderer.send('resize', 960, 740);
    }
  }

  render() {
    return(
      <AppContext.Provider value={{
          loginData: this.state.loginData,
          chatOptions: this.state.chatOptions,
          language: this.state.language,
          appVersion: process.env.REACT_APP_VERSION
        }
      }>
        <div id="app">
          {this.state.currentForm === "login" ? <Router><LoginFormWithRouter sendLoginResult={this.sendLoginResult.bind(this)} changeLanguage={this.changeLanguage.bind(this)} /></Router> : null}
          {this.state.currentForm === "options" ? <ChatOptionsForm sendChatOptions={this.sendChatOptions.bind(this)} /> : null}
          {this.state.currentForm === "chat" ? <ChatInterfaceParent gotoOptions={this.gotoOptions.bind(this)} exitChat={this.exitChat.bind(this)} /> : null}
        </div>
      </AppContext.Provider>
    )
  }
}

ReactDOM.render(
  <React.StrictMode>
     <ThemeProvider>
    <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
  