import React from 'react';
import AppContext from './AppContext';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { LocalStorageWorker } from './StorageHelper';
import { LoggerCore } from "@video/log-client";

import {
  EncoderUiState,
  VideoClientContext,
  EncoderUiContext,
  VideoClient,
  mediaController,
  types,
} from "@video/video-client-web";

import {
  JoinBroadcastButton,
  CameraButton,
  ControlBar,
  EncoderAudioDeviceSelect,
  EncoderEchoCancellationCheckbox,
  EncoderNoiseSuppressionCheckbox,
  EncoderResolutionSelect,
  EncoderVideo,
  EncoderVideoDeviceSelect,
  FullscreenButton,
  CallContext,
  MediaContainer,
  MicrophoneButton,
  SettingsButton,
  SettingsSidebar,
  TestMicButton,
} from "@video/video-client-web";
import { BrowserWindow } from 'electron/main';
import ChatMessage from './ChatMessage';
import { MediaControllerOptions } from '@video/video-client-core/lib/api';
import { ThemeContext } from './ThemeContext';
import { themes } from './Themes';

interface ModularEncoderProps {
  chatMessage(msg:String,className:String): void;
  sysMessage(msg:String): void;
  sendCallId(newCallId:string): void;
  sendEncoderUI(encoderUi:EncoderUiState):void;
  sendVideoClient(vc:VideoClient):void;
  sendVideoDeviceId(newDeviceId:string): void;
  onCameraSelect(): void;
  broadcasting: boolean;
  resolutionWidth: number;
  resolutionHeight: number;
  userLanguage: string;
  restartEncoder: boolean;
  restartEncoderCallback(): void;
  broadcastFailed: boolean;
  restartBroadcastCallback(): void;
  girlDisconnected: boolean;
  girlDisconnectedCallback(): void;
  createBroadcastLog(): void;
  logBroadcastAction(action:string,param?:string): void;
  stagingMode: boolean;
}
interface LoginData {
  producerID:string;
}
interface ChatOptionsData {
  // todo
}
interface ModularEncoder {
  props: ModularEncoderProps;
  state: ModularEncoderState;
  activeBroadcast: types.BroadcastAPI| null;
  stagingMode:boolean;
  logger:LoggerCore;
  settingsSidebarRef:any;
  encoderResolutionSelectDIVRef:any;
  broadcasting:boolean;
  checkBroadcastingStatusTimer:NodeJS.Timeout;
  errorCreatingCallCounter: number;
  checkPropsChangeTimer: NodeJS.Timer;
  lowBitrateReported:boolean;
  broadcastLogCreated:boolean;
}
interface ModularEncoderState {
  encoderDevicesSelected:boolean;
  encoderDevicesSelectable:boolean;
  encoderResolutionSelectable:boolean;
  vc:VideoClient | null;
  encoderUi:EncoderUiState | null;
  call:types.CallAPI | null;
  errmsg:string;
  statusmsg:string;
  tokenFetchFailCount:number;
  userLanguage:string;
  theme: keyof typeof themes; // Hier wird das Theme hinzugefügt
}

class ModularEncoder extends React.Component<ModularEncoderProps> {
  static contextType = AppContext;

  constructor(props:ModularEncoderProps) {
    super(props);
    const savedTheme = localStorage.getItem("theme") as keyof typeof themes || "newstandard";
    this.state = {
      encoderDevicesSelected:false,
      encoderDevicesSelectable:false,
      encoderResolutionSelectable:false,
      vc:null,
      encoderUi:null,
      call:null,
      errmsg:"",
      statusmsg:"",
      tokenFetchFailCount: 0,
      userLanguage: this.props.userLanguage,
      theme: savedTheme,
    }

    this.stagingMode = false;

    this.logger = new LoggerCore("guppyEncoder");
    this.activeBroadcast = null;

    this.settingsSidebarRef = React.createRef();
    this.encoderResolutionSelectDIVRef = React.createRef();
    this.lowBitrateReported = false;
    this.broadcastLogCreated = false;

    library.add(faSpinner);
  }

  componentDidMount() {
    this.initMediaController();
    this.checkPropsChangeTimer = setInterval(this.checkPropsChange.bind(this), 1000);
  }

  componentWillUnmount() {
    if (this.state.encoderUi !== null) {
      this.state.encoderUi.dispose();
      this.setState({encoderUi: null});
      (this.context as any).encoderUi = null;
    }
    if (this.state.vc !== null) {
      this.state.vc.dispose();
      this.setState({vc: null});
    }
    if(this.state.call !== null) {
      this.state.call.dispose("Encoder Component Unmount");
      this.setState({call:null});
    }
    clearInterval(this.checkPropsChangeTimer as any);
    clearInterval(this.checkBroadcastingStatusTimer);
    if(this.logger) {
      this.logger.destroy();
    }
  }

  checkPropsChange() {
    if(this.props.userLanguage !== this.state.userLanguage) {
      this.setState({userLanguage: this.props.userLanguage});
    }
    if(this.props.restartEncoder) {
      this.props.restartEncoderCallback();
      this.restartEncoder();
    }
    if(this.props.broadcastFailed) {
      this.restartBroadcast();
    }
    if(this.props.girlDisconnected) {
      this.girlDisconnected();
    }
  }

  restartBroadcast = async() => {
    this.props.sysMessage("Restarting Broadcast");

    this.props.restartBroadcastCallback();

    this.props.logBroadcastAction("restartBroadcast");

    if(this.activeBroadcast !== null) {
      this.logger.info("Broadcast has failed, restarting " + this.activeBroadcast.streamName + " for userid " + (this.context as any).loginData?.producerID);
      this.activeBroadcast.dispose();
      this.activeBroadcast = null;
    }

    if(this.state.call) {
      this.state.call.dispose("Disposing videocall because Broadcast has failed and we are going to restart it");
      this.setState({call: null});
    }

    if(this.state.vc) {
      let myUserID:string = (this.context as any).loginData?.producerID as string;
      let newCall: types.CallAPI;
      let callOpts:types.CallOptions = {
        userId: myUserID
      };
      
      newCall = await this.state.vc.createCall(callOpts);
      this.props.logBroadcastAction("callCreated",newCall.id);
      
      //newCall.on('webrtcStats', this.callStats.bind(this));
      newCall.on('error', this.callError.bind(this));
      this.setState({call:newCall});
      this.props.sysMessage("VideoCall created " + newCall.id);
      this.activeBroadcast = null;
      setTimeout(this.doCheckBroadcast.bind(this),500);
    }
  }

  callError(err:types.IVideoClientError) {
    this.props.logBroadcastAction("callError " + err.message);
  }

  callStats(data:any) {
    if (data.videoOutboundBitrate <= 4000000) {
      if(!this.lowBitrateReported) {
        this.lowBitrateReported = true;
        this.props.logBroadcastAction("lowBitrate " + data.videoOutboundBitrate);
      }
    }
  }

  girlDisconnected = async() => {
    this.props.sysMessage("Ending Broadcast girlDisconnected");

    this.props.logBroadcastAction("endGirlDisconnected");

    if(this.activeBroadcast !== null) {
      this.logger.info("Girl Disconnected, ending brodcast " + this.activeBroadcast.streamName + " for userid " + (this.context as any).loginData?.producerID);
      this.activeBroadcast.dispose();
      this.activeBroadcast = null;
    }

    if(this.state.call) {
      this.state.call.dispose("Disposing videocall because girl disconnected");
      this.setState({call: null});
    }
    if(this.state.vc) {
      this.state.vc.dispose();
      this.setState({vc: null});
    }

    this.props.girlDisconnectedCallback();
  }

  chatMessage = (msg:String,className:String = "chat-message") => {
    this.props.chatMessage(msg,className);
  }

  restartEncoder = async () => {
    if(this.state.encoderUi !== null) {
      this.state.encoderUi.dispose();
      this.setState({encoderUi: null});
      (this.context as any).encoderUi = null;
    }
    if(this.state.vc !== null) {
      this.state.vc.dispose();
      this.setState({vc: null});
    }
    if(this.state.call !== null) {
      this.state.call.dispose("Encoder Restart");
      this.setState({call:null});
    }
    this.initMediaController();
  }

  initVideoClient = async() => {
    if((this.context as any).loginData === null) {
      this.setState({errmsg: "loginData is null"});
      return;
    }

    let streamKeyURL:string = 'https://streamauth.guppy.live/FetchStreamKey.aspx?ProducerID=' + encodeURIComponent((this.context as any).loginData.producerID);
    if(this.stagingMode) {
      streamKeyURL += "&staging=1";
    }

    this.props.sysMessage("Fetching StreamKey...");
    this.setState({statusmsg:"Fetching StreamKey..."});

    let streamKeyResponseData:any
    try {
      let streamKeyResponse = await fetch(streamKeyURL);
      streamKeyResponseData = await streamKeyResponse.json();
    } catch(error:any) {
      this.setState({errmsg: "Error fetching StreamKey: " + error.toString()});
    }

    let intResult:string = streamKeyResponseData.result;

    if((this.context as any).loginData === null) {
      this.setState({errmsg:"loginData is null"});
      return;
    }

    let opts:types.VideoClientOptions;
    let myUserID:string = (this.context as any).loginData?.producerID as string;
    if(this.stagingMode) {
      opts = {
        userId: myUserID,
        backendEndpoints: ["https://guppy-staging.devspace.lsea3.generflow.com"],
        token: this.fetchTokenRefresh.bind(this),
        displayName: (this.context as any).loginData.pseudo,
      };
    } else {
      opts = {
        userId: myUserID,
        backendEndpoints: ["https://guppy-prod-euw1d.generflow.com"],
        token: this.fetchTokenRefresh.bind(this),
        displayName: (this.context as any).loginData.pseudo,
      };
    }

    //this.props.chatMessage("Creating VideoClient...","chat-sysmessage");

    let newVc:VideoClient = new VideoClient(opts);

    this.setState({vc: newVc});
    this.props.sendVideoClient(newVc);

    setTimeout(this.doCheckBroadcast.bind(this),500);
  }

  initCall = async() => {
    let myUserID:string = (this.context as any).loginData?.producerID as string;
    /*if(myUserID === "a63e9b124e794ed1a9e0a447b334e084") {
      myUserID = "a63e9b124e794ed1a9e0a447b334e08x";
    }*/

    if(this.broadcastLogCreated === false) {
      this.props.createBroadcastLog();
      this.broadcastLogCreated = true;
    }

    let newCall:types.CallAPI;

    try {
      let callOpts:types.CallOptions = {
        userId: myUserID
      };
      if(this.state.vc) {
        if(this.stagingMode) {
          this.props.sysMessage("Creating videocall in staging mode...");
        } else {
          this.props.sysMessage("Creating videocall in production mode...");
        }
        newCall = await this.state.vc.createCall(callOpts);
        this.props.logBroadcastAction("callCreated",newCall.id);
        //newCall.on('webrtcStats', this.callStats.bind(this));
        //newCall.on('error', this.callError.bind(this));
        this.props.sysMessage("VideoCall created " + newCall.id);
        this.setState({call:newCall});
        setTimeout(this.doCheckBroadcast.bind(this),500);
      } else {
        this.initVideoClient();
      }
    } catch(e:any) {
      this.props.chatMessage("Error creating call: " + e.toString(),"chat-sysmessage");
      this.errorCreatingCallCounter++;
    }
  }

  checkBroadcastingStatus() {
    if(this.props.broadcasting !== this.broadcasting) {
      this.errorCreatingCallCounter = 0;
      this.broadcasting = this.props.broadcasting;
      this.doCheckBroadcast();
    }
  }

  fetchTokenRefresh = async():Promise<string> => {
    let url:string;

    this.setState({tokenFetchState: "Fetching Token"});
    this.setState({statusmsg: "Fetching Token"});

    if(this.stagingMode) {
      this.props.sysMessage("Encoder Token refresh in staging mode...");
    }

    let tokenURL:string = 'https://streamauth.guppy.live/FetchToken.aspx?ProducerID=' + encodeURIComponent((this.context as any).loginData.producerID);
    if(this.stagingMode) {
      tokenURL += "&staging=1";
    }

    try {
        const response = await fetch(tokenURL);
        //this.props.chatMessage("Refreshed Token","chat-sysmessage");
        this.setState({statusmsg: "Refreshed Token"});
        const tokenResponse = await response.json();
        /*if(this.stagingMode) {
          this.props.chatMessage("token: " + JSON.stringify(tokenResponse) ,"chat-encodermessage");
        }*/
        return tokenResponse.token;
    } catch (error) {
        this.setState({statusmsg: "Error | "});
        this.setState({errmsg: "Token Error"});
        this.setState({tokenFetchFailCount: this.state.tokenFetchFailCount + 1});
    }

    return "";
  }

  initMediaController = async () => {
    try {
      this.setState({statusmsg: "Initializing Controller..."});
      await mediaController.init();

      let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
      let myDeviceId:string = myLocalStorage.get("videoDeviceId");

      let mediaStreamController:types.MediaStreamControllerAPI;
      const mediaStreamOptions:types.MediaStreamControllerOptions = {
        defaultConstraints: {
          audio: {
            autoGainControl: { exact: true },
            channelCount: { min: 1, ideal: 2 },
            echoCancellation: { exact: true },
            latency: { ideal: 0 },
            noiseSuppression: { exact: true },
            sampleRate: { min: 8000, ideal: 48000, max: 48000 },
            sampleSize: { min: 16, ideal: 32 }
          },
          video: {
            width: {min: 640, ideal: 1280},
            height: {min: 480, ideal: 720}
          },
          screencapture: {}
        },
        fallbackConstraints: {
          audio: {
            autoGainControl: { ideal: true },
            channelCount: { min: 1, ideal: 2 },
            echoCancellation: { ideal: true },
            latency: { ideal: 0 },
            noiseSuppression: { ideal: true },
            sampleRate: { min: 8000, ideal: 48000, max: 48000 },
            sampleSize: { min: 16, ideal: 32 }
          },
          video: {
            width: {min: 640, ideal: 1280},
            height: {min: 480, ideal: 720}
          },
          screencapture: {}
        },
        replaceTracks: true,
        waitingDelay: 500,
        defaultLockPolicy: 1,
      };

      //mediaStreamController = await mediaController.requestController(mediaStreamOptions);
      mediaStreamController = await mediaController.requestController();

      try {
        let newEncoderUI:EncoderUiState = new EncoderUiState(mediaStreamController);
        this.setState({encoderUi: newEncoderUI});
        (this.context as any).encoderUi = newEncoderUI;
        newEncoderUI.viewSettings = true;
        this.setState({encoderDevicesSelectable: true});
        setTimeout(this.setupQualitySelect.bind(this),1000);
        if(myDeviceId !== "") {
          mediaStreamController.videoDeviceId = myDeviceId;
        }
        this.props.sendEncoderUI(newEncoderUI);
      } catch(e2:any) {
        this.setState({errmsg: "Error initializing EncoderUI: " + e2.toString()});
        return;
      }
    } catch(e:any) {
      this.setState({errmsg: "Error initializing mediaStreamController: " + e.toString()});
      return;
    }
  }

  checkEncoderReady = async () => {
    if(this.state.encoderUi) {
      this.props.sysMessage("Encoder is ready...");
      this.setState({encoderDevicesSelectable: true});
    }
  }

  doCheckBroadcast = async () => {
    if(this.broadcasting) {
      if(this.state.encoderUi) {
        let myCtrl:types.MediaStreamControllerAPI = this.state.encoderUi.mediaStreamController as types.MediaStreamControllerAPI;
        if(this.state.call) {
          if(myCtrl) {
            let broadcastCount:number = 0;
            let existingBroadcast:types.BaseBroadcast | null = null;

            this.state.call.broadcasts.forEach((element: types.BaseBroadcast) => {
              broadcastCount = broadcastCount + 1
              existingBroadcast = element;
            });
            
            //this.props.chatMessage("Found " + broadcastCount + " existing broadcasts","");

            if(broadcastCount > 0 && existingBroadcast !== null) {
              let workBroadcast:types.BroadcastAPI = existingBroadcast as types.BroadcastAPI;
              if(workBroadcast.state === "paused") {
                this.props.sysMessage("Resuming Broadcast " + workBroadcast.streamName);
                this.logger.info("Resuming Broadcast " + workBroadcast.streamName + " for userid " + (this.context as any).loginData?.producerID);
                workBroadcast.resume();
                this.props.sendCallId(this.state.call.id);
                this.props.logBroadcastAction("resumeBroadcast");
              }
              this.activeBroadcast = workBroadcast;
              return;
            }
            
            if(this.activeBroadcast === null) {
              let myBroadcast:types.BroadcastAPI = await this.state.call.broadcast(myCtrl,{streamName: "livecam"});
              this.activeBroadcast = myBroadcast;
              this.props.sysMessage("Started Broadcast " + myBroadcast.streamName + "|" + myBroadcast.state);
              this.logger.info("Started Broadcast " + myBroadcast.streamName + " for userid " + (this.context as any).loginData?.producerID + ", state: " + myBroadcast.state);
              this.props.sendCallId(this.state.call.id);
              this.props.logBroadcastAction("startedBroadcast");
            } else {
              if(this.activeBroadcast.state === "paused") {
                this.props.sysMessage("Resuming Broadcast " + this.activeBroadcast.streamName);
                this.logger.info("Resuming Broadcast " + this.activeBroadcast.streamName + " for userid " + (this.context as any).loginData?.producerID);
                this.activeBroadcast.resume();
                this.props.sendCallId(this.state.call.id);
                this.props.logBroadcastAction("resumeBroadcast");
              }
            }
          } else {
            this.props.chatMessage("MediaStreamController not ready...","chat-sysmessage");
          }
        } else {
          if(this.errorCreatingCallCounter < 10) {
            this.initCall();
          } else {
            //props.chatMessage("VideoCall not ready...","chat-sysmessage");
            this.props.chatMessage("---------------------------------------------------------","chat-sysmessage");
            this.props.chatMessage("Verbindung konnte nicht eingerichtet weren, bitte klicke auf Seite neu laden oder F5 und versuche es noch einmal","chat-sysmessage");
            this.props.chatMessage("---------------------------------------------------------","chat-sysmessage");
            this.props.chatMessage("Connection could not be established, please press F5 or reload the page and try again.","chat-sysmessage");
            this.props.chatMessage("---------------------------------------------------------","chat-sysmessage");
          }
        }
      } else {
        this.props.chatMessage("Encoder UI not set up...","chat-sysmessage");
      }
    } else {
      // broadcasting is false
      if(this.activeBroadcast !== null) {
        this.props.sysMessage("Pausing Broadcast " + this.activeBroadcast.streamName);
        this.logger.info("Pausing Broadcast " + this.activeBroadcast.streamName + " for userid " + (this.context as any).loginData?.producerID);
        this.activeBroadcast.dispose();
        this.activeBroadcast = null;
        if(this.state.call) {
          this.state.call.dispose("Pausing Broadcast");
          this.setState({call: null});
        }
        if(this.state.vc) {
          this.state.vc.dispose();
          this.setState({vc: null});
        }
        this.props.logBroadcastAction("pauseBroadcast");
      }
    }
  }

  doContinue() {
    if(this.state.encoderUi) {
      let encoderCtrl:types.MediaStreamControllerAPI = this.state.encoderUi.mediaStreamController as types.MediaStreamControllerAPI;
      if(encoderCtrl) {
        this.props.sendVideoDeviceId(encoderCtrl.videoDeviceId as string);
      }
      this.state.encoderUi.viewSettings = false;
    }
    this.setState({encoderDevicesSelected: true});
    this.props.onCameraSelect();
    this.checkBroadcastingStatusTimer = setInterval(this.checkBroadcastingStatus.bind(this), 250);
  }

  videoDeviceChanged = async () => {
    if(this.state.theme === "newstandard") {

    } else {
      
    }
    if(this.state.encoderUi) {
      let myCtrl:types.MediaStreamControllerAPI = this.state.encoderUi.mediaStreamController as types.MediaStreamControllerAPI;
      if(myCtrl) {
        if(this.settingsSidebarRef.current) {
          let selectBox:HTMLSelectElement | null = null;
          let newDeviceId:string = "";

          let children:HTMLCollection = this.settingsSidebarRef.current.children;
          for (let i = 0; i < children.length; i++) {
            let curChild:Element = children[i];
            let subchildren:HTMLCollection = curChild.children;
            for (let h = 0; h < subchildren.length; h++) {
              let curSubChild:any = subchildren[h];
              if(curSubChild) {
                if(curSubChild.tagName.toLowerCase() === "select") {
                  let subsubchildren:HTMLCollection = curSubChild.children;
                  for (let j = 0; j < subsubchildren.length; j++) {
                    let curSubSubChild:Element = subsubchildren[j];
                    let myInnerHTML:string | null = curSubSubChild.innerHTML;
                    if(myInnerHTML) {
                      if(myInnerHTML == "Select a camera") {
                        selectBox = curSubChild as HTMLSelectElement;
                        this.props.sysMessage("Selected Camera " + this.getCameraNameFromDeviceId(curSubChild.value));
                        newDeviceId = curSubChild.value;
                        try {
                          let myLocalStorage:LocalStorageWorker = new LocalStorageWorker();
                          myLocalStorage.add("videoDeviceId",curSubChild.value);
                        } catch(e:any) {
                          this.props.sysMessage("Error saving videoDeviceId: " + e.toString());
                        }

                        break;
                      }
                    }
                  }
                }
              }
            }
          }

          if(selectBox !== null) {
            let subsubchildren:HTMLCollection = selectBox.children;
            for (let j = 0; j < subsubchildren.length; j++) {
              if(subsubchildren[j].getAttribute("value") === newDeviceId && newDeviceId !== "") {
                this.props.sysMessage("Changing Camera to " + subsubchildren[j].innerHTML);
                subsubchildren[j].setAttribute("selected","selected");
                myCtrl.videoDeviceId = newDeviceId;
                
                this.setState({encoderResolutionSelectable: false});
                setTimeout(this.setupQualitySelect.bind(this),1000);
              } else {
                subsubchildren[j].removeAttribute("selected");
              }
            }
          }
        }
      }
    }
  }

  getCameraNameFromDeviceId(devicdeId:string) {
    if(this.settingsSidebarRef.current) {
      let selectBox:HTMLSelectElement | null = null;

      let children:HTMLCollection = this.settingsSidebarRef.current.children;
      for (let i = 0; i < children.length; i++) {
        let curChild:Element = children[i];
        let subchildren:HTMLCollection = curChild.children;
        for (let h = 0; h < subchildren.length; h++) {
          let curSubChild:any = subchildren[h];
          if(curSubChild) {
            if(curSubChild.tagName.toLowerCase() === "select") {
              let subsubchildren:HTMLCollection = curSubChild.children;
              for (let j = 0; j < subsubchildren.length; j++) {
                let curSubSubChild:Element = subsubchildren[j];
                let myInnerHTML:string | null = curSubSubChild.innerHTML;
                if(myInnerHTML) {
                  if(myInnerHTML == "Select a camera") {
                    selectBox = curSubChild as HTMLSelectElement;
                    break;
                  }
                }
              }
            }
          }
        }
      }

      if(selectBox !== null) {
        let subsubchildren:HTMLCollection = selectBox.children;
        for (let j = 0; j < subsubchildren.length; j++) {
          if(subsubchildren[j].getAttribute("value") === devicdeId) {
            return subsubchildren[j].innerHTML;
          }
        }
      }
    }

    return "";
  }

  writeCurrentResolution() {
    if(this.state.encoderUi) {
      let myCtrl:types.MediaStreamControllerAPI = this.state.encoderUi.mediaStreamController as types.MediaStreamControllerAPI;
      if(myCtrl) {
        if(myCtrl.resolution) {
          this.props.sysMessage(myCtrl.resolution.toString());
        }
      }
    }
  }

  audioDeviceChanged() {
    if(this.state.encoderUi) {
      let myCtrl:types.MediaStreamControllerAPI = this.state.encoderUi.mediaStreamController as types.MediaStreamControllerAPI;
      if(myCtrl) {
        if(this.settingsSidebarRef.current) {
          let children:HTMLCollection = this.settingsSidebarRef.current.children;
          for (let i = 0; i < children.length; i++) {
            let curChild:Element = children[i];
            let subchildren:HTMLCollection = curChild.children;
            for (let h = 0; h < subchildren.length; h++) {
              let curSubChild:any = subchildren[h];
              if(curSubChild) {
                if(curSubChild.tagName.toLowerCase() === "select") {
                  let subsubchildren:HTMLCollection = curSubChild.children;
                  for (let j = 0; j < subsubchildren.length; j++) {
                    let curSubSubChild:Element = subsubchildren[j];
                    let myInnerHTML:string | null = curSubSubChild.innerHTML;
                    if(myInnerHTML) {
                      if(myInnerHTML == "Select a microphone") {
                        this.props.sysMessage("Changing Microphone...");
                        myCtrl.audioDeviceId = curSubChild.value;
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  resolutionChanged() {
    this.props.sysMessage("resolutionChanged...");
    if(this.state.encoderUi) {
      let myCtrl:types.MediaStreamControllerAPI = this.state.encoderUi.mediaStreamController as types.MediaStreamControllerAPI;
      if(myCtrl) {
        if(this.settingsSidebarRef.current) {
          let children:HTMLCollection = this.settingsSidebarRef.current.children;
          for (let i = 0; i < children.length; i++) {
            let curChild:Element = children[i];
            let subchildren:HTMLCollection = curChild.children;
            for (let h = 0; h < subchildren.length; h++) {
              let curSubChild:any = subchildren[h];
              if(curSubChild) {
                if(curSubChild.tagName.toLowerCase() === "select") {
                  let subsubchildren:HTMLCollection = curSubChild.children;
                  for (let j = 0; j < subsubchildren.length; j++) {
                    let curSubSubChild:Element = subsubchildren[j];
                    let myInnerHTML:string | null = curSubSubChild.innerHTML;
                    if(myInnerHTML) {
                      if(myInnerHTML == "Select a Resolution") {
                        this.props.sysMessage("Changing Resolution...");
                        myCtrl.resolution = curSubChild.value;
                        setTimeout(this.writeCurrentResolution.bind(this),1500);
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  setupQualitySelect() {
    if(this.state.encoderUi) {
      let myCtrl:types.MediaStreamControllerAPI = this.state.encoderUi.mediaStreamController as types.MediaStreamControllerAPI;
      if(myCtrl) {
        if(myCtrl.inVideoDeviceTransition) {
          setTimeout(this.setupQualitySelect.bind(this),1000);
          return;
        }
      }
    }

    let resSelectFound:Boolean = false;
    let elementsToRemove:any = new Array();
    let resSelectBox:HTMLSelectElement | null = null;
    let resolutionsFound:number = 0;

    if(this.encoderResolutionSelectDIVRef.current) {
      let children:HTMLCollection = this.encoderResolutionSelectDIVRef.current.children;
      for (let i = 0; i < children.length; i++) {
        let curChild:Element = children[i];
        let subchildren:HTMLCollection = curChild.children;
        for (let h = 0; h < subchildren.length; h++) {
          let curSubChild:any = subchildren[h];
          if(curSubChild) {
            if(curSubChild.tagName.toLowerCase() === "select") {
              let subsubchildren:HTMLCollection = curSubChild.children;
              for (let j = 0; j < subsubchildren.length; j++) {
                let curSubSubChild:Element = subsubchildren[j];
                let myInnerHTML:string | null = curSubSubChild.innerHTML.trim();
                if(myInnerHTML) {
                  if(myInnerHTML === "Select a Resolution") {
                    resSelectBox = curSubChild as HTMLSelectElement;
                    resSelectFound = true;
                    break;
                  }
                }
              }
            }
          }
        }
      }

      if(resSelectBox !== null) {
        //props.chatMessage("Found resSelectBox","");
        let subsubchildren:HTMLCollection = resSelectBox.children;
        for (let j = 0; j < subsubchildren.length; j++) {
          if(subsubchildren[j].getAttribute("value") !== null) {
            let myAttributeValue:string = subsubchildren[j].getAttribute("value")!;
            if(myAttributeValue !== "") {
              //props.chatMessage("Found resolution: " + subsubchildren[j].getAttribute("value"),"");
              resolutionsFound++;
            }
          }
        }
      }

      if(resolutionsFound > 0) {
        for(let e = 0;e < elementsToRemove.length;e++) {
          elementsToRemove[e].remove();
        }
        this.setState({encoderResolutionSelectable: true});
      } else {
        this.setState({encoderResolutionSelectable: false});
        setTimeout(this.setupQualitySelect.bind(this),1000);
      }
    }
  }

  getText(exp:string) {
    if((this.context as any).language === "de") {
      switch(exp) {
        case "SettingUpEncoder":
          return "Kameraauswahl wird initialisiert..."
        case "SelectYourVideoAudioDevice":
          return "Wähle unten Deine Kamera und Dein Mikrofon aus";
        case "ContinueButton":
          return "Weiter";
        case "YourVideoAudioDeviceIsBeingInitialized":
          return "Versuche auf Kamera und Mikrofon zuzugreifen, einen Moment bitte..."
      }
    } else {
      switch(exp) {
        case "SettingUpEncoder":
          return "Setting up devices..."
        case "SelectYourVideoAudioDevice":
          return "Select your webcam and audio input device below";
        case "ContinueButton":
          return "Continue";
          case "YourVideoAudioDeviceIsBeingInitialized":
            return "Trying to access camera and microphone, please wait..."
      }
    }
  }

  render() {
    if (this.state.encoderUi == null) {
      return <div>
        <Alert variant="info" className="mt-4">
          {this.getText("SettingUpEncoder")}
          { this.state.statusmsg !== "" ? this.state.statusmsg : null }
        </Alert>
        { this.state.errmsg !== "" ? 
          <Alert variant="danger" className="mt-4">
            {this.state.errmsg}
          </Alert>
          : null
        }
      </div>;
    }

    return (
      
      <ThemeContext.Consumer>
      {({ theme }) => (
      <VideoClientContext.Provider value={this.state.vc}>
        <EncoderUiContext.Provider value={this.state.encoderUi}>
          <MediaContainer>
            <EncoderVideo />
            <SettingsSidebar>
              <div className="settingsSidebarDIV" ref={this.settingsSidebarRef}  style={{
          backgroundColor: theme.chatBackgroundColor,
          color: theme.mainTextColor
        }}
>
                <Alert variant="info" className="mt-4 hidemobile">
                  {this.getText("SelectYourVideoAudioDevice")}
                </Alert>
                { this.state.encoderDevicesSelectable ? <EncoderVideoDeviceSelect onChange={this.videoDeviceChanged.bind(this)} /> : null }
                { this.state.encoderDevicesSelectable ? <EncoderAudioDeviceSelect onChange={this.audioDeviceChanged.bind(this)} /> : null }
                { this.state.encoderDevicesSelectable ? <div id="encoderResolutionSelect" ref={this.encoderResolutionSelectDIVRef} style={{display: this.state.encoderResolutionSelectable ? "block" : "none"}}><EncoderResolutionSelect /></div> : null }
                { !this.state.encoderDevicesSelectable ? <Alert variant="warning" className="mt-4"><FontAwesomeIcon icon={["fas", "spinner"] } spin /> {this.getText("YourVideoAudioDeviceIsBeingInitialized")}</Alert> : null }
                <Button className="mt-4" variant="primary" disabled={!this.state.encoderDevicesSelectable} onClick={this.doContinue.bind(this)}>{ !this.state.encoderDevicesSelectable ? <FontAwesomeIcon icon={["fas", "spinner"] } spin /> : <FontAwesomeIcon icon={["fas", "hand-point-right"] } /> } {this.getText("ContinueButton")}</Button>
              </div>
            </SettingsSidebar>
          </MediaContainer>
        </EncoderUiContext.Provider>
      </VideoClientContext.Provider>
               )}</ThemeContext.Consumer>
    );
  }
}

export default ModularEncoder;