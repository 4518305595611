import React from 'react';
import AppContext from './AppContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar,faGem,faPhone,faVolumeUp,faWebcam,faMicrophone,faUserLock,faStickyNote } from '@fortawesome/pro-solid-svg-icons';
import Badge from 'react-bootstrap/Badge';
import axios from 'axios';
import { AxiosResponse } from 'axios';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { ThemeContext } from './ThemeContext';
import { themes } from './Themes';
export interface UserListEntryProps {
    userType:string;
    pseudo:string;
    isFromCMS:boolean;
    guid:string;
    sound:boolean;
    lovense:boolean;
    dildo:boolean;
    cam2cam:boolean;
    userMic:boolean;
    privateSession: boolean;
    multiPrivate:boolean;
    updateNotes:boolean;
    selectCallback: (pseudo:string,guid:string) => void;
    openNotesCallback: (pseudo:string,guid:string) => void;
}

interface UserListEntry {
    state: UserListEntryState;
    props: UserListEntryProps;
    checkUpdateNotesIntervalID: NodeJS.Timeout;
    selectCallback: (pseudo:string,guid:string) => void;
    openNotesCallback: (pseudo:string,guid:string) => void;
}

interface UserListEntryState {
    userType:string;
    guid:string;
    pseudo:string;
    isFromCMS:boolean;
    sound:boolean;
    lovense:boolean;
    dildo:boolean;
    cam2cam:boolean;
    userMic:boolean;
    privateSession: boolean;
    multiPrivate:boolean;
    numberOfNotes: number;
    estatus:string;
    updateNotes: boolean;
}

class UserListEntry extends React.Component {
    static contextType = AppContext;

    constructor(props:UserListEntryProps) {
        super(props);
        this.selectCallback = props.selectCallback;
        this.openNotesCallback = props.openNotesCallback;

        this.state = {
            userType: props.userType,
            guid: props.guid,
            pseudo: props.pseudo,
            isFromCMS: props.isFromCMS,
            sound: props.sound,
            lovense: props.lovense,
            dildo: props.dildo,
            cam2cam: props.cam2cam,
            userMic: props.userMic,
            privateSession: props.privateSession,
            multiPrivate: props.multiPrivate,
            numberOfNotes: -1,
            estatus: "",
            updateNotes: props.updateNotes
        }

        library.add(faStar,faGem,faPhone,faVolumeUp,faWebcam,faMicrophone,faUserLock,faStickyNote);
    }

    componentDidMount() {
        if(this.props.userType !== "sender") {
            this.checkUpdateNotesIntervalID = setInterval(this.checkUpdateNotes.bind(this),5000);
        }
    }

    componentWillUnmount() {
        if(this.props.userType !== "sender") {
            clearInterval(this.checkUpdateNotesIntervalID);
        }
    }

    checkUpdateNotes() {
        if(this.props.updateNotes) {
            this.fetchNumNotes();
        }
    }

    getUserPseudo() {
        if(this.props.userType === "voyeur" || this.props.userType === "chatvoyeur" || this.props.userType === "voyeurcns24") {
            return "Voyeur";
        }

        return this.props.pseudo;
    }

    handleSelectUser() {
        if(this.props.userType === "sender") {
            this.selectCallback("","");
        } else {
            this.selectCallback(this.props.pseudo,this.props.guid);
        }
    }

    openNotes() {
        if(this.props.userType === "sender") {
            return;
        } else {
            this.openNotesCallback(this.props.pseudo,this.props.guid);
        }
    }

    fetchNumNotes = async() => {
        //const axios = require('axios').default;

        let url:string = "https://streamauth.guppy.live/FetchUserNotes.aspx?action=getnum&guid=" + encodeURIComponent(this.props.guid);
    
        try {
            const response = await axios.get(url);
            this.setState({numberOfNotes:response.data.numnotes});
        } catch (error) {
            this.setState({estatus:"error fetching notes"});
        }
    }

    render() {
        const savedTheme = localStorage.getItem("theme") as keyof typeof themes || "newstandard";
        if (savedTheme === "newstandard") {
            return (
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <div>
    {this.props.userType === "freeaccount" && (
        <FontAwesomeIcon
            icon={["fas", "star"]}
            style={{
                color: "#FFD700",
                fontSize: "1.5rem",
                flex: "0 1 auto", // Flexibles Verhalten für Platzierung
            }}
        />
    )}
    {this.props.isFromCMS && (
        <FontAwesomeIcon
            icon={["fas", "gem"]}
            style={{
                color: "#8E44AD",
                fontSize: "1.5rem",
                flex: "0 1 auto",
            }}
        />
    )}
    {["cns24pbcuser", "pbcuser", "pbctime"].includes(this.props.userType) && (
        <FontAwesomeIcon
            icon={["fas", "phone"]}
            style={{
                color: "#3498DB",
                fontSize: "1.5rem",
                flex: "0 1 auto",
            }}
        />
    )}
    <span
        onClick={this.handleSelectUser.bind(this)}
        style={{
            cursor: "pointer",
            fontWeight: "bold",
            fontSize: "1rem",
            color: "#ECF0F1",
            flex: "1 1 auto", // Nimmt mehr Platz ein, wenn verfügbar
        }}
    >
        {this.props.pseudo}
    </span>
    {this.props.sound && (
        <FontAwesomeIcon
            icon={["fas", "volume-up"]}
            style={{
                color: "#E74C3C",
                fontSize: "1.5rem",
                flex: "0 1 auto",
                marginLeft: "10px",
            }}
        />
    )}
    {this.props.lovense && (
        <span
            style={{
                color: "#FF4500",
                fontWeight: "bold",
                flex: "0 1 auto",
                marginLeft: "10px",
            }}
        >
            [lovense]
        </span>
    )}
    {this.props.dildo && (
        <img
            src={"dildo_control_icon.png"}
            alt="Dildo Control"
            style={{
                width: "32px",
                height: "32px",
                flex: "0 1 auto",
            }}
        />
    )}
    {this.props.cam2cam && (
        <FontAwesomeIcon
            icon={["fas", "webcam"]}
            style={{
                color: "#1ABC9C",
                fontSize: "1.5rem",
                flex: "0 1 auto",
                marginLeft: "10px",
            }}
        />
    )}
    {this.props.userMic && (
        <FontAwesomeIcon
            icon={["fas", "microphone"]}
            style={{
                color: "#2ECC71",
                fontSize: "1.5rem",
                flex: "0 1 auto",
                marginLeft: "10px",
            }}
        />
    )}
    {this.props.privateSession && (
        <FontAwesomeIcon
            icon={["fas", "user-lock"]}
            style={{
                color: "#E67E22",
                fontSize: "1.5rem",
                flex: "0 1 auto",
                marginLeft: "10px",
            }}
        />
    )}
    {this.props.multiPrivate && (
        <FontAwesomeIcon
            icon={["fas", "volume-up"]}
            style={{
                color: "#E74C3C",
                fontSize: "1.5rem",
                flex: "0 1 auto",
                marginLeft: "10px",
            }}
        />
    )}
    {this.state.numberOfNotes >= 0 && (
        <Badge
            onClick={this.openNotes.bind(this)}
            style={{
                backgroundColor: "#3498DB",
                color: "#FFF",
                padding: "4px 8px",
                borderRadius: "5px",
                fontSize: "0.9rem",
                cursor: "pointer",
                flex: "0 1 auto",
                marginLeft: "10px",
            }}
        >
            {this.state.numberOfNotes}{" "}
            <FontAwesomeIcon icon={["fas", "sticky-note"]} />
        </Badge>
    )}
    {this.state.estatus !== "" && (
        <Badge
            style={{
                backgroundColor: "#E67E22",
                color: "#FFF",
                padding: "5px 10px",
                borderRadius: "5px",
                fontSize: "0.9rem",
                flex: "0 1 auto",
                marginLeft: "10px",
            }}
        >
            {this.state.estatus}
        </Badge>
    )}
</div>

 
                )}</ThemeContext.Consumer>
            )
        }
        else {
            return (
                <ThemeContext.Consumer>
                    {({ theme }) => (
                        <div style={{ color: theme.mainTextColor, paddingLeft: '10px', paddingRight: '10px' }} className={this.props.userType === "voyeur" || this.props.userType === "chatvoyeur" || this.props.userType === "voyeurcns24" ? "userListEntryInvisible" : "userListEntry"}>
                            {this.props.userType === "freeaccount" ? <FontAwesomeIcon icon={["fas", "star"]} /> : null}
                            {this.props.isFromCMS ? <FontAwesomeIcon icon={["fas", "gem"]} /> : null}
                            {this.props.userType === "cns24pbcuser" || this.props.userType === "pbcuser" || this.props.userType === "pbctime" ? <FontAwesomeIcon icon={["fas", "phone"]} /> : null}
                            <span onClick={this.handleSelectUser.bind(this)}>{this.props.pseudo}</span>
                            {this.props.sound ? <FontAwesomeIcon icon={["fas", "volume-up"]} /> : null}
                            {this.props.lovense ? "[lovense]" : null}
                            {this.props.dildo ? <img src={"dildo_control_icon.png"} width="32" height="32" /> : null}
                            {this.props.cam2cam ? <FontAwesomeIcon icon={["fas", "webcam"]} /> : null}
                            {this.props.userMic ? <FontAwesomeIcon icon={["fas", "microphone"]} /> : null}
                            {this.props.privateSession ? <FontAwesomeIcon icon={["fas", "user-lock"]} /> : null}
                            {this.props.multiPrivate ? <FontAwesomeIcon icon={["fas", "volume-up"]} /> : null}
                            {this.state.numberOfNotes >= 0 ? <Badge onClick={this.openNotes.bind(this)}>{this.state.numberOfNotes} <FontAwesomeIcon icon={["fas", "sticky-note"]} /></Badge> : null}
                            {this.state.estatus !== "" ? <Badge>{this.state.estatus}</Badge> : null}
                        </div>
                    )}</ThemeContext.Consumer>
            )
        }
    }

}

export default UserListEntry;