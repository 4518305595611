import Button from 'react-bootstrap/Button';
import { ListGroup, ModalBody } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const DildoPortSelect = (props) => {
    //const [dildoPorts, setDildoPorts] = React.useState(false);
    
    const selectDildoPort = (comName) => {
        props.selectDildoPort(comName);
    }
    return(
<Modal show={true} onHide={props.closeCallback} centered className="ToyOverlay">
  <Modal.Header closeButton className="border-0" style={{ backgroundColor: '#1e1e2f' }}>
    <Modal.Title className="fs-5 fw-semibold text-light" style={{ color: '#00b4d8', letterSpacing: '0.8px' }}>
      Dildo-Control
    </Modal.Title>
  </Modal.Header>
  <Modal.Body style={{ backgroundColor: '#1e1e2f', color: '#ffffff', padding: '20px' }}>
    <ListGroup className="mt-4" style={{ borderRadius: '15px' }}>
      {props.dildoPorts.map((port, index) => (
        <ListGroup.Item
          key={index}
          className="d-flex justify-content-between align-items-center mb-3"
          style={{
            backgroundColor: '#272738',
            color: '#ffffff',
            borderRadius: '10px',
            padding: '15px',
            border: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <span style={{ fontWeight: '600', letterSpacing: '0.5px' }}>{port.friendlyName}</span>
          <Button
            onClick={selectDildoPort.bind(this, port.path)}
            variant="outline-light"
            size="sm"
            style={{
              borderColor: '#00b4d8',
              color: '#00b4d8',
              padding: '10px 20px',
              borderRadius: '30px',
              textTransform: 'uppercase',
              fontWeight: '500',
              letterSpacing: '0.5px',
            }}
          >
            Select
          </Button>
        </ListGroup.Item>
      ))}
    </ListGroup>
  </Modal.Body>
  <Modal.Footer className="border-0" style={{ backgroundColor: '#1e1e2f' }}>
    <Button
      variant="secondary"
      size="sm"
      onClick={props.closeCallback}
      style={{
        color: '#ffffff',
        backgroundColor: '#6c757d',
        borderColor: '#6c757d',
        borderRadius: '30px',
        padding: '10px 20px',
        textTransform: 'uppercase',
        fontWeight: '500',
      }}
    >
      Close
    </Button>
  </Modal.Footer>
</Modal>

      
        
    )
}

export default DildoPortSelect;