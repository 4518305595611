import React from 'react';
import AppContext from './AppContext';

class ChatMessage extends React.Component {
    static contextType = AppContext;

    render() {
        if(this.props.fromPart === "") {
            return(
                <div className={this.props.className} style={{color: this.props.color, paddingLeft: '10px', paddingRight: '10px'}}>{this.props.msg}</div>
            )
        } else {
            return(
                <div className={this.props.className} style={{color: this.props.color,  paddingLeft: '10px', paddingRight: '10px'}}>{this.props.fromPart} : <span className={this.props.messageClass} style={{color: this.props.messageColor}}>{this.props.msg}</span></div>
            )
        }
    }
}

export default ChatMessage;