import React, { Component, RefObject } from "react";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChatMessage from "./ChatMessage"; // Importiere deine ChatMessage-Komponente
import { chatMessageStateObject } from "./ChatInterface"; // Stelle sicher, dass der Pfad korrekt ist

// Definiere die Typen der Props
interface ChatContainerProps {
  chatFontSize: number;
  chatBackgroundColor: string;
  chatTextColor: string;
  videoPaneCols: number;
  showAutoTexts: boolean;
  chatMessages: chatMessageStateObject[];
  chatOptions: {
    autotext1: string;
    autotext2: string;
    autotext3: string;
    autotext4: string;
    autotext5: string;
  };
  chatTargetPseudo: string;
  canWrite: boolean;
  currentChatMessage: string;
  chatMessageContainerRef: RefObject<HTMLDivElement> | null;
  chatInput: RefObject<HTMLInputElement> | null;
  selectUser: (pseudo: string, guid: string) => void;
  videoMoveRight: () => void;
  chatFontPlus: () => void;
  chatFontMinus: () => void;
  getColorFromClassName: (className: string) => string;
  getText: (key: string) => string;
  sendChatText: () => void;
  handleChatMessageKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleChatMessageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  
}
const ChatContainer2024: React.FC<ChatContainerProps> = (props) => {
    const sanitizeInput = (input: string) => {
        return input.replace(/\bto\b/g, "").trim(); // Entfernt alle "to" und trimmt Leerzeichen
      };
  return (
    <div
      id="chatMessageContainer"
      style={{
        fontSize: props.chatFontSize,
        backgroundColor: props.chatBackgroundColor,
        color: props.chatTextColor,
        position: "relative", // Relativ für absolute Positionierung der Kinder
        height: "100%", // Nutze die gesamte Höhe des Viewports
        overflow: "hidden", // Verhindert, dass Inhalte überlaufen
      }}
      ref={props.chatMessageContainerRef}
    >
      <div id="chatMessageButtons">
        {props.videoPaneCols === 0 ? (
          <Button
            id="btnVideoMoveRight2"
            onClick={props.videoMoveRight}
            className="mr-1"
            variant="secondary"
            size="sm"
          >
            <FontAwesomeIcon icon={["fas", "arrow-square-right"]} />
          </Button>
        ) : null}
        <Button
          id="btnFontPlus"
          onClick={props.chatFontPlus}
          variant="secondary"
          size="sm"
        >
          <FontAwesomeIcon icon={["fas", "text-height"]} />
        </Button>
        <Button
          id="btnFontMinus"
          onClick={props.chatFontMinus}
          className="ml-1"
          variant="secondary"
          size="sm"
        >
          <FontAwesomeIcon icon={["fas", "text"]} />
        </Button>
      </div>
      {props.showAutoTexts ? (
        <div id="autoTextContainer">
          #1: {props.chatOptions.autotext1}
          <br />
          #2: {props.chatOptions.autotext2}
          <br />
          #3: {props.chatOptions.autotext3}
          <br />
          #4: {props.chatOptions.autotext4}
          <br />
          #5: {props.chatOptions.autotext5}
        </div>
      ) : null}
<div
  id="chatMessages"
  style={{
    paddingBottom: "60px", // Platz für den Eingabebereich schaffen
    height: "calc(100% - 60px)", // Höhe anpassen, um den Eingabebereich auszuschließen
    overflowY: "auto", // Ermöglicht Scrollen bei vielen Nachrichten
    paddingTop: "15px",
  }}
>
  {props.chatMessages.map((msg, index) => (
    <div
      key={msg.id}
      /*onClick={
        msg.fromPart !== props.chatTargetPseudo
        ? () => {
            const sanitizedFromPart = sanitizeInput(msg.fromPart);
            const sanitizedToPart = sanitizeInput(msg.msgPart);
    
            // Überprüfen, ob die bereinigten Werte zulässig sind
            if (
              sanitizedFromPart !== props.chatTargetPseudo &&
              sanitizedToPart !== sanitizedFromPart &&
              sanitizedFromPart !== ""
            ) {
              props.selectUser(sanitizedFromPart, msg.guid);
            }
          }
        : undefined
      }*/
      
      style={{
        cursor: "pointer", // Visuelles Feedback beim Hover
        color: props.getColorFromClassName(msg.className), // Benutzerdefinierte Farbe
      }}
    >
      <ChatMessage
        className={msg.className}
        color={props.getColorFromClassName(msg.className)}
        msg={msg.msg}
        fromPart={msg.fromPart}
        messageClass={msg.messageClass}
        messageColor={props.getColorFromClassName(msg.messageClass)}
      />
    </div>
  ))}
</div>
     <div
  id="chatInputContainer"
  style={{
    display: "flex",
    alignItems: "center",
    padding: "10px",
  }}
>
  <InputGroup
    className="mr-2 ml-2"
    style={{
      flex: 1, // Nimmt den gesamten Platz ein
      borderRadius: "20px",
      padding: "5px",
      backgroundColor: "#ffffff", // Weiße Eingabefläche
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Leichter Schatten
    }}
  >
    {/* Prefix für den Empfänger */}
    <InputGroup.Prepend>
      <InputGroup.Text
        style={{
            display: "flex",
            alignItems: "center",
            padding: "8px 12px",
            borderRadius: "20px 0 0 20px",
            backgroundColor: "#f1f3f5",
            fontSize: "14px",
            fontWeight: "500",
            color: "#495057",
            border: "1px solid #dee2e6",
            borderRight: "none",
        }}
      >
        {props.chatTargetPseudo === ""
          ? props.getText("ToAll")
          : props.getText("To") + " " + props.chatTargetPseudo}
      </InputGroup.Text>
    </InputGroup.Prepend>

    {/* Eingabefeld */}
    <FormControl
      ref={props.chatInput}
      disabled={!props.canWrite}
      value={props.currentChatMessage}
      onKeyDown={props.handleChatMessageKeyDown}
      onChange={props.handleChatMessageChange}
      placeholder={props.getText("Chatmessage")}
      style={{
        border: "none", // Entfernt den Rahmen
        outline: "none", // Entfernt den Fokus-Rand
        boxShadow: "none", // Entfernt den Schatten (Bootstrap)
        fontSize: "16px",
        color: "#333",
        padding: "10px",
      }}
    />

    {/* Senden-Button */}
    <InputGroup.Append>
      <Button
        variant="primary"
        disabled={!props.canWrite}
        onClick={props.sendChatText}
        style={{
          borderRadius: "20px",
          fontWeight: "bold",
          padding: "6px 16px",
          fontSize: "14px",
        }}
      >
        Send
      </Button>
    </InputGroup.Append>
  </InputGroup>
</div>

    </div>
  );
};

export default ChatContainer2024;
