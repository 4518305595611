import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { ListGroup, Card, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryFull, faBatteryThreeQuarters, faBatteryHalf, faBatteryQuarter, faBatteryEmpty } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const translations = {
    de: {
        select: 'Wähle',
        needDevice: 'Du musst ein Gerät in der "Lovense Connect Software" hinzugefügt haben.',
        showDevices: 'Geräte anzeigen',
        showOldMethods: 'Mein Lush wird nicht angezeigt.',
        oldMethodsToConnect: 'Falls Dein Lush nicht angezeigt wird, kannst Du die Buttons unten versuchen um Dich zu verbinden.',
        locallyConnected: 'Lokal per Dongle',
        connectedViaLAN: 'Suche im Netzwerk',
        devicesCollected: 'Geräte ermittelt',
        lushnotfound: 'Lush wurde nicht gefunden!',
    },
    en: {
        select: 'Select',
        needDevice: 'You need to add a device in the "Lovense Connect Software".',
        showDevices: 'Show Devices',
        showOldMethods: 'My Lush is not shown when clicking on Show Devices',
        oldMethodsToConnect: 'If your Lush is not shown, try the buttons below to connect.',
        locallyConnected: 'Locally via Dongle',
        connectedViaLAN: 'Search via Network',
        devicesCollected: 'Devices Collected',
        lushnotfound: 'Lush was not found!',
    },
};

const useTranslation = (language = 'en') => {
    const resolvedLanguage = language === 'auto' ? 'en' : language;
    return (key) => translations[resolvedLanguage]?.[key] || key;
};

const LovenseDeviceSelectPre = ({ language: initialLanguage = 'auto', onChoice }) => {
    const t = useTranslation(initialLanguage);
    console.log(initialLanguage);
    const [showOldMethods, setShowOldMethods] = React.useState(false);

    return (
        <Card className="text-center p-4 rounded" style={{ maxWidth: '450px', margin: '20px auto', backgroundColor: '#1e1e2f', color: '#ffffff' }}>
            <Card.Body>
                {/* Titel modernisiert */}
                <Card.Title
                    className="mb-4"
                    style={{
                        fontSize: '1.8rem',
                        fontWeight: '600',
                        color: '#ffffff',
                        letterSpacing: '0.8px',
                        textShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    {t('lushnotfound')}
                </Card.Title>

                <hr className="my-4" style={{ borderColor: '#444', borderWidth: '1px' }} />

                <Button
                    className="mb-4"
                    variant="outline-light"
                    onClick={() => setShowOldMethods(!showOldMethods)}
                    size="lg"
                    style={{
                        fontWeight: '500',
                        color: '#00b4d8',
                        borderColor: '#00b4d8',
                        borderRadius: '30px',
                        padding: '10px 20px',
                        textTransform: 'uppercase',
                    }}
                >
                    {t('showOldMethods')}
                </Button>

                {showOldMethods && (
                    <>
                        <Card.Text className="mt-4 mb-3" style={{ fontSize: '1.1rem', color: '#adb5bd' }}>
                            {t('oldMethodsToConnect')}
                        </Card.Text>
                        <div className="d-flex justify-content-center gap-3">

                            <Button
                                className="mb-4 mr-3"
                                variant="outline-light"
                                onClick={() => onChoice('local')}
                                size="sm"
                                style={{
                                    fontWeight: '500',
                                    color: '#00b4d8',
                                    borderColor: '#00b4d8',
                                    borderRadius: '30px',
                                    padding: '10px 20px',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {t('locallyConnected')}
                            </Button>

                            <Button
                                className="mb-4"
                                variant="outline-light"
                                onClick={() => onChoice('LAN')}
                                size="sm"
                                style={{
                                    fontWeight: '500',
                                    color: '#00b4d8',
                                    borderColor: '#00b4d8',
                                    borderRadius: '30px',
                                    padding: '10px 20px',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {t('connectedViaLAN')}
                            </Button>
                        </div>
                    </>
                )}
            </Card.Body>
        </Card>



    );
};

const getBatteryIcon = (batteryLevel) => {
    if (batteryLevel >= 75) return faBatteryFull;
    if (batteryLevel >= 50) return faBatteryThreeQuarters;
    if (batteryLevel >= 25) return faBatteryHalf;
    if (batteryLevel > 0) return faBatteryQuarter;
    return faBatteryEmpty;
};

const getBatteryColor = (batteryLevel) => {
    if (batteryLevel >= 50) return 'text-success';
    if (batteryLevel >= 25) return 'text-warning';
    return 'text-danger';
};

const LovenseDeviceSelectResultsDevice = ({ device, language, onSelect }) => {
    const t = useTranslation(language);

    return (
        <ListGroup.Item className="p-3 mb-3 shadow-sm rounded d-flex align-items-center justify-content-between bg-white">
            <div className="d-flex align-items-center flex-grow-1 justify-content-between">
                <span
                    className="text-dark fw-bold nick-name"
                    style={{
                        fontSize: '1.1rem',
                        color: '#333',
                        fontWeight: 'bold',
                        letterSpacing: '0.5px',
                        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {device.nickName}
                </span>

                <span className="d-flex align-items-center text-muted ms-4" style={{ fontSize: '1.2rem' }}>
                    <FontAwesomeIcon
                        icon={getBatteryIcon(device.battery)}
                        className={getBatteryColor(device.battery)}
                        style={{ marginRight: '0.2rem', marginTop: '3px' }}
                    />
                    {device.battery}%
                </span>
            </div>
            <Button
                variant="primary"
                onClick={() => onSelect(device.domain, device.httpPort, device.httpsPort, device.id)}
                size="md"
                style={{ padding: '0.5rem 1rem', fontSize: '0.9rem', marginLeft: '1rem' }}
            >
                {t('select')}
            </Button>
        </ListGroup.Item>
    );
};

const LovenseDeviceSelectResults = ({ lovenseDevices, onSelect, language }) => (
    <ListGroup className="shadow-sm rounded mb-4">
        {lovenseDevices.map((device, index) => (
            <LovenseDeviceSelectResultsDevice key={index} device={device} onSelect={onSelect} language={language} />
        ))}
    </ListGroup>
);

const LovenseDeviceSelect = ({ language: initialLanguage, onSelect, choice, producerID, pseudo }) => {
    const [language] = React.useState(initialLanguage);
    const t = useTranslation(language);
    const [showDeviceList, setShowDeviceList] = React.useState(false);
    const [deviceList, setDeviceList] = React.useState([]);
    const [queryError, setQueryError] = React.useState('');
    const [queryRunning, setQueryRunning] = React.useState(false);
    const [queryInfo, setQueryInfo] = React.useState('');

    React.useEffect(() => {
        if (choice) onChoice(choice);
    }, [choice]);

    const onChoice = (method) => {
        setQueryError('');
        setQueryInfo(`Querying devices ${method}`);
        setDeviceList([]);
        setQueryRunning(true);

        const handleSuccess = (devices) => {
            setQueryRunning(false);
            setQueryInfo('');

            // Geräte filtern, die den Status 1 haben
            const filteredDevices = devices.filter((device) => device.status === 1);
console.log(filteredDevices);
            if (filteredDevices.length === 0) {
                setShowDeviceList(false); // Zeige LovenseDeviceSelectPre weiterhin an
            } else {
                setDeviceList(filteredDevices);
                setShowDeviceList(true);
            }
        };


        const handleError = (error) => {
            setQueryError(`Query Error: ${error.toString()}`);
            setQueryInfo('');
            setQueryRunning(false);
        };

        if (method === 'WebAPI' && window.lovense?.getToys) {
            const toysArray = window.lovense.getToys();
            if (!toysArray) {
                handleError('No Toys found');
            } else {
                handleSuccess(
                    toysArray.map((toy) => ({
                        ...toy,
                        domain: '127-0-0-1.lovense.club',
                        httpsPort: 30010,
                    }))
                );
            }
        } else {
            const urls = {
                LAN: 'https://api.lovense.com/api/lan/getToys',
                local: 'https://127-0-0-1.lovense.club:30010/GetToys',
            };
            axios
                .get(urls[method])
                .then((response) => {
                    const devices = Object.entries(response.data).flatMap(([, curObject]) =>
                        Object.entries(curObject.toys || {}).map(([, curToy]) => ({
                            ...curToy,
                            platform: curObject.platform || 'local',
                            domain: curObject.domain || '127-0-0-1.lovense.club',
                            httpsPort: curObject.httpsPort || 30010,
                        }))
                    );
                    if (devices.length === 0) {
                        handleError('No devices found');
                    } else {
                        handleSuccess(devices);
                    }
                })
                .catch(handleError);
        }
    };

    return (
        <div
            style={{
                width: '60%',
                padding: '20px',
                margin: '0 auto',
            }}
        >
            <div className="query-header mb-4">
                <div className="d-flex justify-content-between w-100 align-items-center">
                    <span className="text-dark fw-bold fs-4" style={{ marginBottom: '1rem' }}>
                        {queryInfo}
                    </span>
                    <Button
                        variant="outline-primary"
                        onClick={() => onChoice(choice)}
                        className="ms-3"
                        style={{ padding: '0.5rem', borderRadius: '50%' }}
                    >
                        <FontAwesomeIcon icon={faSync} spin={queryRunning} />
                    </Button>

                </div>
            </div>
            {showDeviceList ? (
                <LovenseDeviceSelectResults
                    lovenseDevices={deviceList}
                    onSelect={onSelect}
                    language={language}
                />
            ) : (
                <LovenseDeviceSelectPre
                    onChoice={onChoice}
                    language={language}
                    producerID={producerID}
                    pseudo={pseudo}
                />
            )}
        </div>


    );
};

export default LovenseDeviceSelect;
