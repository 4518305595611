import React, { Component, FormEvent } from 'react';
import { ListGroup, ListGroupItem, Alert, Pagination, PaginationItem, PaginationLink, Form, FormGroup, Button, Label, Input, InputGroup, InputGroupText, Badge, ButtonToolbar, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownMenu, DropdownItem, Row, Col, DropdownToggle } from 'reactstrap';
import { LocalStorageWorker } from '../StorageHelper';
import { LoginData, ChatOptionsData } from '../AppContext';
import { CryptoHelper } from '../CryptoHelper.js';
import AppContext from "../AppContext";
import { AppCtx, AppContextInterface, ApiHelper } from '../AppContextInterface';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DateTime } from 'luxon';
import { VideoModal } from './VideoModal';
import { ImageModal } from './ImageModal';
import { MessagingPost, MessagingPostMediaData } from './Timeline';
import { MediaData, BuyMediaData } from './Inbox';
import { MessengerWrite } from './MessengerWrite';
import { MessengerConversationControl } from './MessengerConversationControl';
import { UserListData } from './UserList';
import { Tooltip, UncontrolledTooltip } from 'reactstrap';
import { convertToObject } from 'typescript';
import { PageData } from './Timeline';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import '../components/styles/Messenger.css';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { layer } from '@fortawesome/fontawesome-svg-core';
import { ThemeContext } from '../ThemeContext';
interface MessengerProps {
    authtoken: string;
    producerID: string;
    children?: React.ReactNode;

}

export interface Messenger extends React.Component {
    state: MessengerState;
    props: MessengerProps;
    contextType: AppContextInterface;
    subscriptionTiers: SubscriptionTierData[];

}

interface MsgPost {
    msg: string,
    receiverPseudo: string,
    conversationID: string,
    freeMedia: MessagingPostMediaData[],
    payMedia: MessagingPostMediaData[],
    price: number
}

interface MessengerState {
    infoMessage: string;
    errorMessage: string;
    fetching: boolean;
    language: string;
    conversations: MessengerConversation[];
    selectedUserPseudo: string;
    conversationID: string;
    conversationListID: string;
    listID: string;
    reloadMessages: boolean;
    priceModal: boolean;
    price: number;
    priceInputEuro: number;
    priceInputCents: number;
    priceOverrides: PriceOverrideRecord[];
    calculatedPrices: CalculatedPriceRecord[];
    fetchingSubscriptionTiers: boolean;
    fetchingSubscriptionTiersError: string;
    submittingPrice: boolean;
    customListsDropdownOpen: boolean;
    fetchingSystemLists: boolean;
    systemLists: UserListData[];
    fetchingCustomLists: boolean;
    customLists: UserListData[];
    platform: string;
    pagesArray: PageData[];
    curPage: number;
    pageSize: number;
    totalItemsCount: number;
    extraPagesDropdownOpen: boolean;
    maxPages: number;
    producerID: string;
    authtoken: string;
    myColorMode: string;
}

interface PriceOverrideRecord {
    priceEuro: number;
    priceCents: number;
    tierId: number;
}

interface PriceOverrideMessagingPostRecord {
    price: number;
    tierId: number;
}

interface CalculatedPriceRecord {
    price: number;
    tierName: string;
    tierId: number;
}

interface MessengerConversation {
    conversationID: string;
    userPseudo: string;
    unreadFromUser: number;
    lastSendFromUser: Date;
    unreadFromGirl: number;
    listID: string;
    isOnline: boolean;
}

interface MessengerMail {
    msgid: string;
    sendTime: Date;
    clickTime: Date;
    content: string;
    sender: string;
    receiver: string;
    msgtext: string;
    freeMedia: MediaData[];
    payMedia: BuyMediaData[];
    price: number;
}

interface MessageAttachment {
    attachmentID: string;
    attachmentType: string;
    attachmentFileName: string;
    s3Key: string;
    s3Bucket: string;
    isBought: boolean;
    price: number;
}

export interface SubscriptionTierData {
    id: number;
    tierName: string;
    tierDescription: string;
    discountFormula: string | null;
    billingPeriodNum: number | null;
    billingPeriodUnits: string | null;
    subscriptionFunction: string | null;
    subscriptionDiscount: number | null;
    price: number;
}

export class Messenger extends React.Component {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: MessengerProps) {
        super(props);

        let contextLanguage = "en";
        if (this.context) {
            if (this.context.language !== null && this.context.language !== "") {
                contextLanguage = this.context.language;

            }
        }

        let myplatform: string = ApiHelper.getPlatform();


        this.state = {
            infoMessage: "",
            errorMessage: "",
            fetching: false,
            language: contextLanguage,
            selectedUserPseudo: "",
            conversationID: "",
            conversationListID: "",
            conversations: [],
            listID: "",
            reloadMessages: false,
            priceModal: false,
            price: 0,
            priceInputEuro: 0,
            priceInputCents: 0,
            priceOverrides: [],
            calculatedPrices: [],
            fetchingSubscriptionTiers: false,
            fetchingSubscriptionTiersError: "",
            submittingPrice: false,
            customListsDropdownOpen: false,
            fetchingSystemLists: false,
            systemLists: [],
            fetchingCustomLists: false,
            customLists: [],
            platform: myplatform,
            pagesArray: [],
            curPage: 0,
            pageSize: 12,
            totalItemsCount: 0,
            extraPagesDropdownOpen: false,
            maxPages: 5,
            producerID: props.producerID,
            authtoken: props.authtoken,
            myColorMode: "",

        };

    }

    componentDidMount() {
        let myLocalStorage: LocalStorageWorker = new LocalStorageWorker();

        if (myLocalStorage.get("lastpage") !== "messenger") {
            myLocalStorage.add("lastpage", "messenger");
        }
        this.fetchSystemLists();
        this.fetchData("conversations");
        let myColorMode: string = myLocalStorage.get("colorMode");
        this.setState({ myColorMode: myColorMode });
    }
    fetchData = async (listID: string) => {
        this.setState({ fetching: true });
        this.setState({ listID: listID });

        var jsonurl = "https://api.deine.fans/api/messengergirl";
        jsonurl += "?producerID=" + encodeURIComponent(this.state.producerID) + "&authToken=" + encodeURIComponent(this.state.authtoken);
        jsonurl += "&platform=" + encodeURIComponent(this.state.platform);
        jsonurl += "&startIndex=" + encodeURIComponent(this.state.curPage * this.state.pageSize);
        jsonurl += "&numRecords=" + encodeURIComponent(this.state.pageSize);

        jsonurl += "&listID=" + encodeURIComponent(listID);

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetching: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.conversations) {
                this.setState({ conversations: data.conversations, pagesArray: [], totalItemsCount: data.numConversations }, this.handleAfterDataFetched.bind(this));
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetching: false });
        }
    }

    handleAfterDataFetched() {
        this.handlePagination(this.state.totalItemsCount);
    }

    handlePagination(numConversations: number) {
        if (numConversations > 0) {
            let newArray: PageData[] = [];
            for (let si = 0; si < numConversations; si += this.state.pageSize) {
                let newPage: PageData = {
                    page: Math.floor(si / this.state.pageSize),
                    active: Math.floor(si / this.state.pageSize) == this.state.curPage ? true : false
                };
                newArray.push(newPage);
            }
            this.setState({ pagesArray: newArray });
        } else {
            this.setState({ pagesArray: [] });
        }
    }

    fetchSystemLists = async () => {
        this.setState({ fetchingSystemLists: true });

        var jsonurl = "https://api.deine.fans/api/usersystemlists";


        jsonurl += "?producerID=" + encodeURIComponent(this.state.producerID) + "&authToken=" + encodeURIComponent(this.state.authtoken);
        jsonurl += "&platform=" + encodeURIComponent(this.state.platform);


        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ fetchingSystemLists: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }

            if (data.lists) {
                this.setState({ systemLists: data.lists });
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ fetchingSystemLists: false });
        }
    }


    clickList(listID: string) {
        this.fetchData(listID);
    }

    getText(exp: string) {
        if (this.context.language === "de") {
            if (exp === "NoConversations") {
                return "Keine Unterhaltungen vorhanden";
            }
            if (exp === "SetPriceForUserSend") {
                return "Versandpreis für User";
            }
            if (exp === "SetPrice") {
                return "Preise festlegen";
            }
            if (exp === "Eigene Gruppe") {
                return "Eigene Gruppe";
            }
            if (exp === "User mit") {
                return "User mit ";
            }
            if (exp === "Username") {
                return "Pseudo";
            }
            if (exp === "ConfirmDelete") {
                return "Ja, Nachricht löschen";
            }
            if (exp === "Cancel") {
                return "Abbrechen";
            }
            if (exp === "Price") {
                return "Preis";
            }
            if (exp === "RedBorderedImages") {
                return "Rot umrandete Bilder/Videos müssen noch freigeschaltet werden, bevor sie den Usern angezeigt werden.";
            }
            if (exp === "MessengerDescription") {
                return "Der Messenger bietet Dir eine Übersicht über Deine Unterhaltungen mit einzelnen Usern, Du kannst auch kostenpflichtige Inhalte in Nachrichten versenden. Wenn Du lieber eine Übersicht über alle Nachrichten haben möchtest, dann nutze bitte die Funktion 'Posteingang' im Menü.";
            }
            if (exp === "OrangeOutlinedItemsHaveToBeReviewed") {
                return "(orange umrandete Inhalte müssen noch freigeschaltet werden, bevor sie angezeigt werden)";
            }
            if (exp === "MessengerPriceModalTitle") {
                return "Preis für Nachrichtenversand von Usern";
            }
            if (exp === "MessengerPriceDescription") {
                return "Hier kannst du den Preis festlegen, den User für den Versand von Nachrichten an Dich zahlen müssen.";
            }
            if (exp === "CalculatedPrices") {
                return "Preise für einzelne Abo-Level";
            }
            if (exp === "EditSubscriptionTiers") {
                return "Abo-Level bearbeiten";
            }
            if (exp === "DefaultPrice") {
                return "Standardpreis für Nicht-Abonnenten (und falls nichts anderes festgelegt wurde)";
            }
            if (exp === "DefaultPriceUsers") {
                return "Standardpreis für Nicht-Abonnenten";
            }
            if (exp === "abo") {
                return "Deine Abonnenten";
            }
            if (exp === "customers") {
                return "Deine Kunden";
            }
            if (exp === "exabo") {
                return "Ehemalige Abonnenten";
            }
            if (exp === "fans") {
                return "Deine Fans";
            }
            if (exp === "conversations") {
                return "Unterhaltungen";
            }
            if (exp === "customlists") {
                return "Eigene Usergruppen";
            }
            if (exp === "Reload") {
                return "Neuladen";
            }
            if (exp === "More") {
                return "mehr";
            }
        } else {
            if (exp === "NoConversations") {
                return "No conversations found";
            }
            if (exp === "SetPriceForUserSend") {
                return "Price for user for sending messages";
            }
            if (exp === "MessengerPriceModalTitle") {
                return "Price for users for sending messages";
            }
            if (exp === "MessengerPriceDescription") {
                return "Here you can set the price that users have to pay to send you messages.";
            }
            if (exp === "DefaultPriceUsers") {
                return "Default price for non-subscribers";
            }
            if (exp === "DefaultPrice") {
                return "Default price for non-subscribers (and if nothing else is set)";
            }
            if (exp === "CalculatedPrices") {
                return "Calculated prices for individual subscription tiers";
            }
            if (exp === "EditSubscriptionTiers") {
                return "Edit subscription tiers";
            }
            if (exp === "PriceSubmit") {
                return "Set price";
            }
            if (exp === "User mit") {
                return "User with ";
            }
            if (exp === "SetPrice") {
                return "Set price";
            }
            if (exp === "Eigene Gruppe") {
                return "Own group";
            }
            if (exp === "Username") {
                return "Livecam Profile";
            }
            if (exp === "ConfirmDelete") {
                return "Yes, delete message";
            }
            if (exp === "Wirklich Nachricht löschen?") {
                return "Really delete message?";
            }
            if (exp === "Preis festlegen") {
                return "Set price";
            }
            if (exp === "Preis") {
                return "Price";
            }
            if (exp === "Anhänge") {
                return "Attachments";
            }
            if (exp === "OrangeOutlinedItemsHaveToBeReviewed") {
                return "(orange outlined items have to be reviewed, before they will be shown)";
            }
            if (exp === "Kostenlose Inhalte") {
                return "Free content";
            }
            if (exp === "Kostenpflichtige Inhalte") {
                return "Pay content";
            }
            if (exp === "RedBorderedImages") {
                return "Red bordered images/videos have to be reviewed, before users will be able to see them.";
            }
            if (exp === "MessengerDescription") {
                return "The messenger offers you an overview of your conversations with individual users, you can also send paid content in messages. If you would rather have an overview of all messages, please use the function 'Inbox' in the menu."
            }
            if (exp === "abo") {
                return "Your subscribers";
            }
            if (exp === "customers") {
                return "Your customers";
            }
            if (exp === "exabo") {
                return "Former subscribers";
            }
            if (exp === "fans") {
                return "Your fans";
            }
            if (exp === "conversations") {
                return "Conversations";
            }
            if (exp === "customlists") {
                return "Custom user groups";
            }
        }

        return exp;
    }

    clickUser(conversationID: string, listID: string) {
        let myuser: MessengerConversation | undefined = undefined;

        this.setState({ selectedUserPseudo: "" });
        this.setState({ conversationID: "" });

        myuser = this.state.conversations.find(element => element.conversationID === conversationID);

        if (myuser !== undefined && myuser !== null) {
            this.setState({ selectedUserPseudo: myuser.userPseudo });
            this.setState({ conversationID: myuser.conversationID });
            this.setState({ conversationListID: myuser.listID });
        }
    }

    doReloadMessages() {
        if (this.state.conversationID !== "") {
            this.setState({ reloadMessages: true });
        }
    }

    onMessageSent() {
        if (this.state.conversationID !== "") {
            this.setState({ reloadMessages: true });
        }
    }

    onMessagesReloaded() {
        this.setState({ reloadMessages: false });
    }

    onMessageWriteCancel() {
        this.setState({ conversationID: "" });
        this.setState({ conversationListID: "" });
    }

    unselectConversation() {
        this.setState({ conversationID: "" });
        this.setState({ conversationListID: "" });
    }

    clickPriceButton() {
        this.updateSubscriptionTiers();
        this.setState({ priceInputEuro: Math.floor(this.state.price / 100) });
        this.setState({ priceInputCents: Math.floor(this.state.price % 100) });
        this.setState({ priceModal: !this.state.priceModal });
    }

    updateCalculatedPrices() {
        this.setState({ calculatedPrices: [] });
        this.setState({ fetchingSubscriptionTiers: false });
        this.setState({ fetchingSubscriptionTiersError: "" });

        if (this.context === null) {
            return;
        }

        let curPrice: number = this.state.priceInputEuro * 100;
        curPrice += this.state.priceInputCents;

        var calculatedPrices: CalculatedPriceRecord[] = [];
        calculatedPrices.push({ tierName: this.getText("DefaultPriceUsers"), tierId: 0, price: curPrice });
        this.setState({ calculatedPrices: calculatedPrices });

        var jsonurl = "https://api.deine.fans/api/subscriptiontiers?producerid=" + encodeURIComponent(this.state.producerID) + "&authToken=" + encodeURIComponent(this.context.loginData!.fanAPIToken);

        fetch(jsonurl, { method: "GET", headers: { 'Accept': 'application/json', 'Content-Type': 'text/plain' } }).then(async (response: any) => {
            let data = await response.json();

            if (data.tiers) {
                this.setState({ fetchingSubscriptionTiers: false });
                this.setState({ fetchingSubscriptionTiersError: "" });

                var calculatedPrices: CalculatedPriceRecord[] = [];

                let curPrice: number = this.state.priceInputEuro * 100;
                curPrice += this.state.priceInputCents;

                calculatedPrices.push({ tierName: this.getText("DefaultPriceUsers"), tierId: 0, price: curPrice });

                for (var i = 0; i < data.tiers.length; i++) {
                    var subscriptionTier = data.tiers[i];
                    var calculatedPrice = curPrice;

                    let myPriceRecord: PriceOverrideRecord | undefined = this.state.priceOverrides.find((record: PriceOverrideRecord) => record.tierId === subscriptionTier.tierId);

                    if (myPriceRecord) {
                        calculatedPrice = myPriceRecord.priceEuro * 100;
                        calculatedPrice += myPriceRecord.priceCents;
                    }

                    calculatedPrices.push({ tierName: this.getText("User mit") + subscriptionTier.tierName, tierId: subscriptionTier.id, price: calculatedPrice });
                }

                this.setState({ calculatedPrices: calculatedPrices });
            } else {
                this.setState({ fetchingSubscriptionTiers: false });
                this.setState({ fetchingSubscriptionTiersError: "Error fetching subscription tiers: " + data.error });
            }
        });
    }

    updateSubscriptionTiers() {
        this.setState({ fetchingSubscriptionTiers: true });
        this.setState({ fetchingSubscriptionTiersError: "" });

        if (this.context === null) {
            return;
        }

        var jsonurl = "https://api.deine.fans/api/subscriptiontiers?producerid=" + encodeURIComponent(this.state.producerID) + "&authToken=" + encodeURIComponent(this.state.authtoken);

        fetch(jsonurl, { method: "GET", headers: { 'Accept': 'application/json', 'Content-Type': 'text/plain' } }).then(async (response: any) => {
            let data = await response.json();

            if (data.tiers) {
                this.setState({ fetchingSubscriptionTiers: false });
                this.setState({ fetchingSubscriptionTiersError: "" });

                this.subscriptionTiers = [];

                for (var i = 0; i < data.tiers.length; i++) {
                    var subscriptionTier: SubscriptionTierData = data.tiers[i];
                    this.subscriptionTiers.push(subscriptionTier);
                }

                this.updateCalculatedPrices();

                this.getPriceFromAPI();
            } else {
                this.setState({ fetchingSubscriptionTiers: false });
                this.setState({ fetchingSubscriptionTiersError: "Error fetching subscription tiers: " + data.error });
            }
        });
    }

    getSubscriptionTierName(tierId: number) {
        for (var i = 0; i < this.subscriptionTiers.length; i++) {
            var subscriptionTier: SubscriptionTierData = this.subscriptionTiers[i];
            if (subscriptionTier != null) {
                if (subscriptionTier.id === tierId) {
                    if (subscriptionTier.tierName !== "") {
                        return subscriptionTier.tierName;
                    } else {
                        return "Abo-Level " + tierId;
                    }
                }
            }
        }

        return "Abo-Level " + tierId;
    }

    handlePriceSubmit(e: FormEvent) {
        e.preventDefault();
        let newprice: number = this.state.priceInputEuro * 100;
        newprice += this.state.priceInputCents;
        this.setState({ price: newprice });
        this.savePriceToAPI(newprice);
    }

    getPriceFromAPI = async () => {
        if (this.context === null) {
            return;
        }

        var jsonurl = "https://api.deine.fans/api/messengerprops";


        jsonurl += "?producerID=" + encodeURIComponent(this.state.producerID) + "&authToken=" + encodeURIComponent(this.state.authtoken);


        this.setState({ submitting: true });

        let response: any = null;

        try {
            response = await fetch(jsonurl);
            let data = await response.json();
            this.setState({ submitting: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });
                } else {
                    let newPriceOverrides: PriceOverrideRecord[] = [];
                    let myTakenPrice: number = 0;

                    this.setState({ price: data.price });
                    myTakenPrice = data.price;

                    this.setState({ priceInputEuro: Math.floor(myTakenPrice / 100) });
                    this.setState({ priceInputCents: Math.floor(myTakenPrice % 100) });

                    if (data.priceOverrides !== null) {
                        let currec: PriceOverrideMessagingPostRecord;

                        for (let i = 0; i < data.priceOverrides.length; i++) {
                            currec = data.priceOverrides[i];

                            let newrec: PriceOverrideRecord = {
                                tierId: currec.tierId,
                                priceEuro: Math.floor(currec.price / 100),
                                priceCents: currec.price % 100
                            };

                            newPriceOverrides.push(newrec);
                        }
                    }

                    this.setState({ priceOverrides: newPriceOverrides });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error fetching data: " + error.toString() });
            this.setState({ submitting: false });
        }
    }

    savePriceToAPI = async (newprice: number) => {
        if (this.context === null) {
            return;
        }

        this.setState({ errorMessage: "" });
        this.setState({ submittingPrice: false });

        var jsonurl = "https://api.deine.fans/api/messengerprops";

        jsonurl += "?price=" + encodeURIComponent(newprice);


        jsonurl += "&producerID=" + encodeURIComponent(this.state.producerID) + "&authToken=" + encodeURIComponent(this.state.authtoken);


        this.setState({ submittingPrice: true });

        let response: any = null;
        let myPriceOverrides: PriceOverrideMessagingPostRecord[] = [];

        for (let i = 0; i < this.state.priceOverrides.length; i++) {
            let currec: PriceOverrideRecord = this.state.priceOverrides[i];
            let curPrice: number = currec.priceEuro * 100;
            curPrice += currec.priceCents;

            let newrec: PriceOverrideMessagingPostRecord = {
                tierId: currec.tierId,
                price: curPrice
            };

            myPriceOverrides.push(newrec);
        }

        try {
            const settings = {
                method: 'POST',
                body: JSON.stringify(myPriceOverrides),
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            };
            response = await fetch(jsonurl, settings);
            let data = await response.json();
            this.setState({ submittingPrice: false });

            if (typeof (data.title) !== "undefined" && typeof (data.errors) !== "undefined") {
                this.setState({ errorMessage: "Error: " + data.title + " | " + JSON.stringify(data.errors) });
            } else {
                if (data.error !== "" && data.error !== null) {
                    this.setState({ errorMessage: "Error: " + data.error });

                }

                if (data.success) {
                    this.setState({ priceModal: false });
                } else {
                    this.setState({ errorMessage: "Error: " + data.error });
                }
            }
        } catch (error: any) {
            this.setState({ errorMessage: "Error making changes: " + error.toString() });
            this.setState({ submittingPrice: false });
        }
    }

    priceModalClose() {
        this.setState({ priceModal: false });
    }

    changePriceInputEuro(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.value === "") {
            this.setState({ priceInputEuro: 0 }, this.updateCalculatedPrices.bind(this));
        } else {
            if (!isNaN(parseInt(e.target.value))) {
                this.setState({ priceInputEuro: parseInt(e.target.value) }, this.updateCalculatedPrices.bind(this));
            }
        }
    }

    changePriceInputCents(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.value === "") {
            this.setState({ priceInputCents: 0 }, this.updateCalculatedPrices.bind(this));
        } else {
            if (!isNaN(parseInt(e.target.value))) {
                this.setState({ priceInputCents: parseInt(e.target.value) }, this.updateCalculatedPrices.bind(this));
            }
        }
    }

    changePriceOverrideEuro(tierId: number, e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.value === "") {
            let myValue: number = 0;
            let myArray: PriceOverrideRecord[] = this.state.priceOverrides;
            let myPriceRecord: PriceOverrideRecord | undefined = myArray.find((record: PriceOverrideRecord) => record.tierId === tierId);

            if (myPriceRecord) {
                myPriceRecord.priceEuro = myValue;
                let newArray: PriceOverrideRecord[] = this.state.priceOverrides.filter((record: PriceOverrideRecord) => record.tierId !== tierId);
                newArray.push(myPriceRecord);
                this.setState({ priceOverrides: newArray });
            } else {
                let newArray: PriceOverrideRecord[] = myArray;
                newArray.push({ priceEuro: myValue, priceCents: 0, tierId: tierId });
                this.setState({ priceOverrides: newArray });
            }
        } else {
            if (!isNaN(parseInt(e.target.value))) {
                let myValue: number = parseInt(e.target.value);
                let myArray: PriceOverrideRecord[] = this.state.priceOverrides;
                let myPriceRecord: PriceOverrideRecord | undefined = myArray.find((record: PriceOverrideRecord) => record.tierId === tierId);

                if (myPriceRecord) {
                    myPriceRecord.priceEuro = myValue;
                    let newArray: PriceOverrideRecord[] = this.state.priceOverrides.filter((record: PriceOverrideRecord) => record.tierId !== tierId);
                    newArray.push(myPriceRecord);
                    this.setState({ priceOverrides: newArray });
                } else {
                    let newArray: PriceOverrideRecord[] = myArray;
                    newArray.push({ priceEuro: myValue, priceCents: 0, tierId: tierId });
                    this.setState({ priceOverrides: newArray });
                }
            }
        }
    }

    changePriceOverrideCents(tierId: number, e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.value === "") {
            let myValue: number = 0;
            let myArray: PriceOverrideRecord[] = this.state.priceOverrides;
            let myPriceRecord: PriceOverrideRecord | undefined = myArray.find((record: PriceOverrideRecord) => record.tierId === tierId);

            if (myPriceRecord) {
                myPriceRecord.priceCents = myValue;
                let newArray: PriceOverrideRecord[] = this.state.priceOverrides.filter((record: PriceOverrideRecord) => record.tierId !== tierId);
                newArray.push(myPriceRecord);
                this.setState({ priceOverrides: newArray });
            } else {
                let newArray: PriceOverrideRecord[] = myArray;
                newArray.push({ priceCents: myValue, priceEuro: 0, tierId: tierId });
                this.setState({ priceOverrides: newArray });
            }
        } else {
            if (!isNaN(parseInt(e.target.value))) {
                let myValue: number = parseInt(e.target.value);
                let myArray: PriceOverrideRecord[] = this.state.priceOverrides;
                let myPriceRecord: PriceOverrideRecord | undefined = myArray.find((record: PriceOverrideRecord) => record.tierId === tierId);

                if (myPriceRecord) {
                    myPriceRecord.priceCents = myValue;
                    let newArray: PriceOverrideRecord[] = this.state.priceOverrides.filter((record: PriceOverrideRecord) => record.tierId !== tierId);
                    newArray.push(myPriceRecord);
                    this.setState({ priceOverrides: newArray });
                } else {
                    let newArray: PriceOverrideRecord[] = myArray;
                    newArray.push({ priceCents: myValue, priceEuro: 0, tierId: tierId });
                    this.setState({ priceOverrides: newArray });
                }
            }
        }
    }

    findPriceOverrideEuro(tier: CalculatedPriceRecord): string {
        let myPriceRecord: PriceOverrideRecord | undefined = this.state.priceOverrides.find((record: PriceOverrideRecord) => record.tierId === tier.tierId);

        if (myPriceRecord) {
            return myPriceRecord.priceEuro.toString();
        } else {
            return Math.floor(tier.price / 100).toString();
        }
    }

    findPriceOverrideCents(tier: CalculatedPriceRecord): string {
        let myPriceRecord: PriceOverrideRecord | undefined = this.state.priceOverrides.find((record: PriceOverrideRecord) => record.tierId === tier.tierId);

        if (myPriceRecord) {
            return myPriceRecord.priceCents.toString();
        } else {
            return (tier.price % 100).toString();
        }
    }

    formatPrice(price: number) {
        return Math.floor(price / 100) + "," + (price % 100).toString().padStart(2, "0");
    }


    handleExtraPagesDropdownToggle() {
        this.setState({ extraPagesDropdownOpen: !this.state.extraPagesDropdownOpen });
    }

    handlePageChange(newPage: number): void {
        this.setState({ curPage: newPage }, this.fetchData.bind(this, this.state.listID));
    }

    render() {
        console.log(this.state.myColorMode);
        return (
            <ThemeContext.Consumer>
      {({ theme }) => (
            <div>

                <Modal  enforceFocus={false} isOpen={this.state.priceModal} onClosed={this.priceModalClose.bind(this)} >
                    <ModalHeader close={<button className="close" onClick={this.priceModalClose.bind(this)}>×</button>}>
                        {this.getText("MessengerPriceModalTitle")}
                    </ModalHeader>
                    <ModalBody style={{

      }} className={this.state.myColorMode} >
                        <p>{this.getText("MessengerPriceDescription")}</p>
                        <Form onSubmit={this.handlePriceSubmit.bind(this)}>
                            <FormGroup className="mb-2" controlId="formBasicPassword">
                                <Label>{this.getText("DefaultPrice")}</Label>
                                <InputGroup>
                                    <Input value={this.state.priceInputEuro} onChange={this.changePriceInputEuro.bind(this)} type="tel" style={{ textAlign: "right" }} />
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                    </InputGroupText>
                                    <InputGroupText>
                                        ,
                                    </InputGroupText>
                                    <Input value={this.state.priceInputCents} maxLength={2} onChange={this.changePriceInputCents.bind(this)} type="tel" style={{ textAlign: "right" }} />
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <Button disabled={this.state.submittingPrice} variant="primary" type="submit">{this.getText("SetPrice")}</Button>
                        </Form>
                        {this.state.fetchingSubscriptionTiers ? <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="2x" /></p> : null}
                        {this.state.fetchingSubscriptionTiersError !== "" ? <Alert color='warning'>{this.state.fetchingSubscriptionTiersError}</Alert> : null}
                        {this.state.calculatedPrices ?
                            <div className="mt-3">
                                <h6>{this.getText("CalculatedPrices")}</h6>
                                <ListGroup>
                                    {this.state.calculatedPrices.map((price: CalculatedPriceRecord, index: number) => {
                                        return <ListGroupItem key={index}>
                                            <small>{price.tierName} {(price.price / 100).toLocaleString(undefined, { minimumFractionDigits: 2 })}€ [{price.tierId}]</small>
                                            {price.tierId !== 0 ?
                                                <InputGroup size="sm">
                                                    <Input value={this.findPriceOverrideEuro(price)} onChange={this.changePriceOverrideEuro.bind(this, price.tierId)} type="tel" style={{ textAlign: "right" }} />
                                                    <InputGroupText>
                                                        <FontAwesomeIcon icon={solid('euro-sign')} size="sm" />
                                                    </InputGroupText>
                                                    <InputGroupText>,</InputGroupText>
                                                    <Input value={this.findPriceOverrideCents(price)} maxLength={2} onChange={this.changePriceOverrideCents.bind(this, price.tierId)} type="tel" style={{ textAlign: "right" }} />
                                                    <InputGroupText>
                                                        <FontAwesomeIcon icon={solid('cent-sign')} size="sm" />
                                                    </InputGroupText>
                                                </InputGroup> : null}
                                        </ListGroupItem>
                                    })}
                                </ListGroup>
                            </div> : null}
                        <Router><Link to="/subscriptions"><small>{this.getText("EditSubscriptionTiers")}</small></Link></Router>
                    </ModalBody>
                </Modal>


                {this.state.infoMessage !== "" ? <p className='border-bottom mt-2 pb-2'><small>{this.getText("MessengerDescription")}</small></p> : null}

                {this.state.infoMessage !== "" ? <Alert color="info">{this.state.infoMessage}</Alert> : null}
                {this.state.errorMessage !== "" ? <Alert color="danger">{this.state.errorMessage}</Alert> : null}

                        <Row id='MessengerDIV'>
                            <Col xs={12} lg={3} id="MessengerConversationsPane" style={{
                                backgroundColor: theme.mainBackgroundColor,

                            }}
                                className={this.state.conversationID === "" ? "nomsgselected flex-shrink-0" : "msgselected flex-shrink-0 pr-2"}>
                                <div className="d-flex justify-content-between mb-2">
                                    <Button disabled={this.state.fetching} size="sm" onClick={this.fetchData.bind(this, "conversations")}><FontAwesomeIcon icon={solid('refresh')} fixedWidth /> {this.getText("Reload")}</Button>
                                    {this.state.platform === "fans" ? <Button size="sm" onClick={this.clickPriceButton.bind(this)}><FontAwesomeIcon icon={solid('euro')} fixedWidth /> {this.getText("SetPriceForUserSend")}</Button> : null}
                                </div>
                        <hr></hr>
                        <div  className="d-flex justify-content-sm-center Messengerlist">
                            <Button id="btnMessengerList-conversations" size="mx-2" disabled={this.state.fetching} onClick={this.fetchData.bind(this, "conversations")}><FontAwesomeIcon icon={solid('message')} fixedWidth /></Button>
                            {this.state.systemLists.map(list => {
                                return <Button id={"btnMessengerList-" + list.listID} color={this.state.listID === list.listID ? "primary" : "secondary"} size="sm" disabled={this.state.fetching} onClick={this.fetchData.bind(this, list.listID)}>
                                    {list.listID === "abo" ? <FontAwesomeIcon icon={solid('cart-circle-check')} fixedWidth /> : list.listID === "exabo" ? <FontAwesomeIcon icon={solid('cart-circle-xmark')} fixedWidth /> : list.listID === "customers" ? <FontAwesomeIcon icon={solid('cart-shopping')} fixedWidth /> : list.listID === "fans" ? <FontAwesomeIcon icon={solid('heart')} fixedWidth /> : list.listID === "chat" ? <FontAwesomeIcon icon={solid('camera-web')} fixedWidth /> : list.listID === "favorites" ? <FontAwesomeIcon icon={solid('heart')} fixedWidth /> : list.listID === "cmsnewusers" ? <FontAwesomeIcon icon={solid('user-plus')} fixedWidth /> : list.listID === "cmspaidusers" ? <FontAwesomeIcon icon={solid('user-tie')} fixedWidth /> : list.listID === "cmsallusers" ? <FontAwesomeIcon icon={solid('users')} fixedWidth /> : <FontAwesomeIcon icon={solid("list")} fixedWidth />}
                                </Button>
                            })}
                            {this.state.systemLists.map(list => {
                                return <UncontrolledTooltip placement="top" target={"btnMessengerList-" + list.listID}>{this.getText(list.name) + "(" + list.numUsers + ")"}</UncontrolledTooltip>
                            })}

                            <UncontrolledTooltip placement="top" target="btnMessengerList-conversations">{this.getText("conversations")}</UncontrolledTooltip>
                        </div>
                        {this.state.fetching ? (
                            <p className="pt-4"><FontAwesomeIcon icon={solid('cog')} spin size="4x" /></p>
                        ) : (
                            this.state.conversations.length === 0 ? (
                                <p className="pt-4">{this.getText("NoConversations")}</p>
                            ) : (
                                <ul className="list-group mt-2 overflow">
                                    {this.state.conversations.map(conversation => (
                                        <li
                                            key={conversation.conversationID}
                                            style={{
                                                color: theme.secondBackgroundColor,
                                              }} 
                                            className={conversation.conversationID === this.state.conversationID ? "list-group-item messengerUser active" : "list-group-item messengerUser"}
                                            onClick={() => this.clickUser(conversation.conversationID, "conversations")}
                                            x-conversationid={conversation.conversationID}
                                        >
                                            {conversation.userPseudo}
                                            {conversation.unreadFromUser > 0 ? (
                                                <span>
                                                    {conversation.isOnline ? <span className="badge bg-success onlineBadge">online</span> : null}
                                                    <span className="badge bg-secondary">{conversation.unreadFromUser}</span>
                                                </span>
                                            ) : null}
                                        </li>
                                    ))}
                                </ul>
                            )
                        )}
                        {this.state.pagesArray ? this.state.pagesArray.length > 0 ?
                            <Pagination size='s' className='pagination mt-2 mb-0'>
                                {this.state.pagesArray.map((page, index) =>
                                    <PaginationItem style={{ display: "list-item" }} active={page.active} className={page.page < this.state.maxPages ? "" : "d-none"}><PaginationLink onClick={this.handlePageChange.bind(this, page.page)} style={{ color: "#000" }}>{page.page + 1}</PaginationLink></PaginationItem>
                                )}
                                {this.state.pagesArray.length > 10 ?
                                    <Dropdown className='paginationExtraDropdown' isOpen={this.state.extraPagesDropdownOpen} toggle={this.handleExtraPagesDropdownToggle.bind(this)} direction="down">
                                        <DropdownToggle size='sm' caret>{this.state.curPage >= this.state.maxPages ? this.state.curPage : this.getText("More")}</DropdownToggle>
                                        <DropdownMenu className='paginationdropdownDIV'>
                                            {this.state.pagesArray.map((page, index) =>
                                                <DropdownItem className={page.page >= this.state.maxPages ? page.active ? "bg-secondary text-white" : "" : "d-none"} onClick={this.handlePageChange.bind(this, page.page)}>{page.page + 1}</DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </Dropdown> : null}
                            </Pagination>
                            : null : null}
                    </Col>
                    <Col xs={12} lg={9} style={{ display: this.state.conversationID === "" ? "none" : "flex" }} id="MessengerMessagesPane" className="flex-column align-items-stretch flex-shrink-0">
                        {this.state.conversationID !== "" ? <Button color='secondary' size="sm" className='topRightButton' onClick={this.unselectConversation.bind(this)}><FontAwesomeIcon icon={solid('remove')} fixedWidth /></Button> : null}

                        {this.state.conversationID !== "" ?
                            <div className='w-100'>
                                <MessengerConversationControl colormode={this.state.myColorMode} doReload={this.state.reloadMessages} onMessagesReloaded={this.onMessagesReloaded.bind(this)} userPseudo={this.state.selectedUserPseudo} conversationID={this.state.conversationID} />
                                <MessengerWrite colormode={this.state.myColorMode} onCancel={this.onMessageWriteCancel.bind(this)} onMessageSent={this.onMessageSent.bind(this)} userPseudo={this.state.selectedUserPseudo} conversationID={this.state.conversationID} targetListID={this.state.conversationListID} replyToMsgID="" />
                                <hr />
                            </div> : null}
                    </Col>
                </Row>

            </div>
              )}</ThemeContext.Consumer>
        )
    }
}