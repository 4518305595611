import React, { FormEvent } from 'react';
import AppContext from './AppContext';
import { library } from '@fortawesome/fontawesome-svg-core';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import UserNote from './UserNote';
import axios from 'axios';
import { ThemeContext } from './ThemeContext';
import { themes } from './Themes';
// Angenommen, LoginData ist Ihr Typ

export interface UserNotesModalProps {
    pseudo: string;
    guid: string;
    closeCallback: () => void;
    updateNotesCallback: () => void;
}

interface UserNotesModal {
    state: UserNotesModalState;
    props: UserNotesModalProps;
    closeCallback: () => void;
    updateNotesCallback: () => void;
    noteInput: React.RefObject<HTMLTextAreaElement> | null;
}

interface UserNoteData {
    pseudo: string;
    time: string;
    guid: string;
    comment: string;
}

interface UserNotesModalState {
    guid: string;
    pseudo: string;
    estatus: string;
    notes: Array<UserNoteData>;
    formstatus: string;
    insertComment: string;
    noteToDeleteGuid: string;
}

class UserNotesModal extends React.Component {
    static contextType = AppContext;

    constructor(props: UserNotesModalProps) {
        super(props);
        this.closeCallback = props.closeCallback;
        this.updateNotesCallback = props.updateNotesCallback;

        this.state = {
            guid: props.guid,
            pseudo: props.pseudo,
            estatus: "",
            notes: [],
            formstatus: "loading",
            insertComment: "",
            noteToDeleteGuid: ""
        }

        this.noteInput = React.createRef();
    }

    componentDidMount() {
        this.fetchNotes();
    }

    handleAddNote() {
        if (this.state.formstatus === "insert") {
            this.handleInsertCommentSubmit();
        } else {
            this.setState({ formstatus: "insert", insertComment: "" });
            setTimeout(this.setFocusOnInput.bind(this), 1000);
        }
    }

    setFocusOnInput() {
        if (this.noteInput) {
            if (this.noteInput.current) {
                this.noteInput.current.focus();
            }
        }
    }

    handleInsertCommentSubmit = async () => {
        //const axios = require('axios').default;

        this.setState({ estatus: "" });

        let url: string = "https://streamauth.guppy.live/FetchUserNotes.aspx?action=addnote&guid=" + encodeURIComponent(this.state.guid) + "&comment=" + encodeURIComponent(this.state.insertComment) + "&producerID=" + encodeURIComponent(this.context && (this.context as any).loginData.producerID);

        try {
            const response = await axios.get(url);
            if (response.data.status === "ok") {
                this.fetchNotes();
            } else {
                this.setState({ estatus: response.data.status });
            }
        } catch (error) {
            this.setState({ estatus: "error inserting note" });
        }
    }

    confirmDelete() {
        this.deleteNote(this.state.noteToDeleteGuid);
    }

    cancelDelete() {
        this.setState({ noteToDeleteGuid: "" });
    }

    deleteNoteAsk(guid: string) {
        this.setState({ noteToDeleteGuid: guid });
    }

    deleteNote = async (guid: string) => {
        //const axios = require('axios').default;

        this.setState({ estatus: "" });


        let url: string = "https://streamauth.guppy.live/FetchUserNotes.aspx?action=delnote&guid=" + encodeURIComponent(this.state.guid) + "&noteguid=" + encodeURIComponent(guid) + "&producerID=" + encodeURIComponent((this.context as any).loginData.producerID);

        try {
            const response = await axios.get(url);
            if (response.data.status === "ok") {
                this.setState({ noteToDeleteGuid: "" });
                this.fetchNotes();
            } else {
                this.setState({ estatus: response.data.status });
            }
        } catch (error) {
            this.setState({ estatus: "error inserting note" });
        }
    }

    handleInsertCommentChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ insertComment: event.target.value });
    }

    getText(exp: string) {
        if (this.context && (this.context as any).language === "en") {
            switch (exp) {
                case "Notiz hinzufügen":
                    return "Add note";
                case "Notiz zu User eintragen":
                    return "Insert your note about the user";
                case "Keine Notizen zu diesem User gefunden":
                    return "No notes for this user found";
                case "Wirklich diese Notiz löschen?":
                    return "Really delete this note?";
                default:
                    return exp; // Gibt den Originaltext zurück, wenn keine Übersetzung gefunden wird.
            }
        }

        // Gibt den deutschen Text zurück, wenn die Sprache nicht Englisch ist
        return exp;
    }

    fetchNotes = async () => {
        //const axios = require('axios').default;

        this.setState({ formstatus: "loading" });


        let url: string = "https://streamauth.guppy.live/FetchUserNotes.aspx?action=getnotes&guid=" + encodeURIComponent(this.state.guid) + "&producerID=" + encodeURIComponent((this.context as any).loginData?.producerID);

        try {
            const response = await axios.get(url);
            if (response.data.status === "ok") {
                this.setState({ notes: response.data.notes });
                this.setState({ formstatus: "list" });
            } else {
                this.setState({ estatus: response.data.status });
            }
        } catch (error) {
            this.setState({ estatus: "" });
        }
    }

    render() {

        const savedTheme = localStorage.getItem("theme") as keyof typeof themes || "newstandard";
        if (savedTheme === "newstandard") {
            return (
                <Modal
                    show={true}
                    onHide={this.closeCallback.bind(this)}
                    style={{
                        fontFamily: 'Arial, sans-serif',
                        color: 'white',
                    }}
                    centered
                >
                    <Modal.Header
                        closeButton
                        style={{
                            borderBottom: '1px solid #444',
                            backgroundColor: '#1e1e2f',
                            color: '#FFFFFF',
                        }}
                    >
                        <Modal.Title style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                            {this.state.pseudo}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body
                        style={{
                            backgroundColor: '#1e1e2f',
                            color: 'white',
                            padding: '20px',
                        }}
                    >
                        {this.state.estatus === '' ? null : (
                            <Alert
                                variant="danger"
                                style={{
                                    borderRadius: '8px',
                                    backgroundColor: '#FF4C4C',
                                    color: '#FFF',
                                    fontWeight: '500',
                                }}
                            >
                                {this.state.estatus}
                            </Alert>
                        )}

                        {this.state.noteToDeleteGuid === '' ? null : (
                            <div className="mb-2">
                                <Alert
                                    variant="info"
                                    style={{
                                        borderRadius: '8px',
                                        backgroundColor: '#2E2E3A',
                                        color: '#FFF',
                                        fontWeight: '500',
                                    }}
                                >
                                    {this.getText('Wirklich diese Notiz löschen?')}
                                </Alert>
                                <Row>
                                    <Col>
                                        <Button
                                            className="w-100"
                                            variant="danger"
                                            onClick={this.confirmDelete.bind(this)}
                                            style={{
                                                borderRadius: '30px',
                                                fontWeight: 'bold',
                                                backgroundColor: '#FF4C4C',
                                                border: 'none',
                                                color: '#FFF',
                                            }}
                                        >
                                            {this.getText('Löschen')}
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            className="w-100"
                                            variant="secondary"
                                            onClick={this.cancelDelete.bind(this)}
                                            style={{
                                                borderRadius: '30px',
                                                fontWeight: 'bold',
                                                backgroundColor: '#444',
                                                color: '#FFF',
                                                border: 'none',
                                            }}
                                        >
                                            {this.getText('Abbrechen')}
                                        </Button>
                                    </Col>
                                </Row>
                                <hr />
                            </div>
                        )}

                        {this.state.formstatus === 'insert' ? (
                            <Form>
                                <Form.Group>
                                    <Form.Label style={{ fontWeight: 'bold', color: '#AAA' }}>
                                        {this.getText('Notiz zu User eintragen')}
                                    </Form.Label>
                                    <Form.Control
                                        ref={this.noteInput}
                                        as="textarea"
                                        value={this.state.insertComment}
                                        onChange={this.handleInsertCommentChange.bind(this)}
                                        style={{
                                            borderRadius: '12px',
                                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                            border: '1px solid #555',
                                            padding: '10px',
                                            resize: 'none',
                                            backgroundColor: '#2A2A3D',
                                            color: '#FFF',
                                        }}
                                    />
                                </Form.Group>
                            </Form>
                        ) : null}

                        {this.state.formstatus === 'list' ? (
                            <div>
                                {this.state.notes.length === 0 ? (
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            color: '#666',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {this.getText('Keine Notizen zu diesem User gefunden')}
                                    </div>
                                ) : (
                                    this.state.notes.map((obj: UserNoteData, index: any) => (
                                        <div
                                            key={index}
                                            style={{
                                                backgroundColor: '#2A2A3D',
                                                borderRadius: '10px',
                                                boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
                                                padding: '15px',
                                                margin: '10px 0',
                                                color: '#FFF',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div style={{ flex: 1 }}>
                                                <div
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '1rem',
                                                        marginBottom: '5px',
                                                    }}
                                                >
                                                    {obj.pseudo} - {obj.time}
                                                </div>
                                                <div style={{ color: '#BBB' }}>{obj.comment}</div>
                                            </div>
                                            <Button
                                                variant="outline-danger"
                                                size="sm"
                                                onClick={() => this.deleteNoteAsk(obj.guid)}
                                                style={{
                                                    borderRadius: '20px',
                                                    color: '#FF4C4C',
                                                    borderColor: '#FF4C4C',
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                {this.getText('Löschen')}
                                            </Button>
                                        </div>
                                    ))
                                )}
                            </div>
                        ) : null}

                        {this.state.formstatus === 'loading' ? (
                            <div style={{ textAlign: 'center', color: '#666' }}>{this.getText('Loading')}</div>
                        ) : null}
                    </Modal.Body>

                    <Modal.Footer
                        style={{
                            backgroundColor: '#1A1A2E',
                            borderTop: '1px solid #444',
                            justifyContent: 'center',
                            padding: '10px 20px',
                        }}
                    >
                        <Button
                            variant="secondary"
                            onClick={this.closeCallback.bind(this)}
                            style={{
                                borderRadius: '30px',
                                padding: '8px 20px',
                                fontWeight: 'bold',
                                backgroundColor: '#444',
                                border: 'none',
                                color: '#FFF',
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={this.handleAddNote.bind(this)}
                            style={{
                                borderRadius: '30px',
                                padding: '8px 20px',
                                fontWeight: 'bold',
                                background: 'linear-gradient(45deg, #6a11cb, #2575fc)',
                                border: 'none',
                                color: '#FFF',
                            }}
                        >
                            {this.getText('Notiz hinzufügen')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )
        } else {
            return (
                <ThemeContext.Consumer>
                    {({ theme }) => (
                        <Modal show={true} onHide={this.closeCallback.bind(this)} >
                            <Modal.Header closeButton style={{ color: theme.mainTextColor, background: theme.mainBackgroundColor }}>
                                <Modal.Title>{this.state.pseudo}</Modal.Title>
                            </Modal.Header>

                            <Modal.Body style={{ color: theme.mainTextColor, background: theme.mainBackgroundColor }}>
                                {this.state.estatus === "" ? null : <Alert variant="danger">{this.state.estatus}</Alert>}
                                {this.state.noteToDeleteGuid === "" ? null : <div className="mb-2"><Alert variant="info" style={{ color: theme.secondBackgroundColor }}>{this.getText("Wirklich diese Notiz löschen?")}</Alert><Row><Col><Button className="w-100" variant="danger" onClick={this.confirmDelete.bind(this)}>{this.getText("Löschen")}</Button></Col><Col><Button className="w-100" variant="secondary" onClick={this.cancelDelete.bind(this)}>{this.getText("Abbrechen")}</Button></Col></Row><hr></hr></div>}
                                {this.state.formstatus === "insert" ?
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>{this.getText("Notiz zu User eintragen")}</Form.Label>
                                            <Form.Control ref={this.noteInput} as="textarea" value={this.state.insertComment} onChange={this.handleInsertCommentChange.bind(this)} />
                                        </Form.Group>
                                    </Form> : null
                                }
                                {this.state.formstatus === "list" ? <div>{this.state.notes.length === 0 ? this.getText("Keine Notizen zu diesem User gefunden") : this.state.notes.map((obj: UserNoteData, index: any) => <UserNote key={index} deleteCallback={this.deleteNoteAsk.bind(this)} guid={obj.guid} pseudo={obj.pseudo} time={obj.time} comment={obj.comment} />)}</div> : null}
                                {this.state.formstatus === "loading" ? <div>{this.getText("Loading")}</div> : null}
                            </Modal.Body>

                            <Modal.Footer style={{ color: theme.mainTextColor, background: theme.mainBackgroundColor }}>
                                <Button variant="secondary" onClick={this.closeCallback.bind(this)}>Close</Button>
                                <Button variant="primary" onClick={this.handleAddNote.bind(this)}>{this.getText("Notiz hinzufügen")}</Button>
                            </Modal.Footer>
                        </Modal>
                    )}</ThemeContext.Consumer>
            )
        }

    }
}

export default UserNotesModal;