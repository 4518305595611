export const themes = {
    white: {
      mainBackgroundColor: "#fff",
      navBarColor: "light",
      mainTextColor: "#000",
      chatBackgroundColor: "#eee",
      chatTextColor: "#000",
      userListBackgroundColor: "#eee",
      userListTextColor: "#000",
      secondTextColor: "#000",
      sysMessageColor: "blue",
      featureMessageColor: "darkgray",
      enterColor: "green",
      leaveColor: "chocolate",
      chatFromGirlColor: "black",
      chatFromUserColor1: "darkolivegreen",
      chatFromUserColor2: "darkred",
      chatFromUserColor3: "darkmagenta",
      featureRequestColor: "indigo",
      featureAcceptedColor: "mediumseagreen",
      featureDeniedColor: "darkred",
      secondBackgroundColor: "lightgray",
    },
    gray: {
      mainBackgroundColor: "#ddd",
      navBarColor: "lightgray",
      mainTextColor: "#000",
      chatBackgroundColor: "#ccc",
      chatTextColor: "#000",
      userListBackgroundColor: "#ccc",
      userListTextColor: "#000",
      secondTextColor: "#000",
      sysMessageColor: "blue",
      featureMessageColor: "darkgray",
      enterColor: "green",
      leaveColor: "chocolate",
      chatFromGirlColor: "crimson",
      chatFromUserColor1: "darkolivegreen",
      chatFromUserColor2: "darkred",
      chatFromUserColor3: "darkmagenta",
      featureRequestColor: "indigo",
      featureAcceptedColor: "mediumseagreen",
      featureDeniedColor: "darkred",
      secondBackgroundColor: "lightgray",
    },
    sepia: {
      mainBackgroundColor: "#BCAC80",
      navBarColor: "#BCAC80",
      mainTextColor: "#fff",
      secondTextColor: "#000",
      chatBackgroundColor: "#D0C4A0",
      chatTextColor: "#000",
      userListBackgroundColor: "#D0C4A0",
      userListTextColor: "#000",
      sysMessageColor: "blue",
      featureMessageColor: "darkgray",
      enterColor: "green",
      leaveColor: "chocolate",
      chatFromGirlColor: "crimson",
      chatFromUserColor1: "darkolivegreen",
      chatFromUserColor2: "darkred",
      chatFromUserColor3: "darkmagenta",
      featureRequestColor: "indigo",
      featureAcceptedColor: "mediumseagreen",
      featureDeniedColor: "darkred",
      loginFormDIVBackgroundColor: "#f0f0f0",
      secondBackgroundColor: "rgb(208, 196, 160)",
    },
    dark: {
      mainBackgroundColor: "#343a40",
      navBarColor: "dark",
      secondTextColor: "#000",
      mainTextColor: "#fff",
      chatBackgroundColor: "#222",
      chatTextColor: "#fff",
      userListBackgroundColor: "#222",
      userListTextColor: "#000",
      sysMessageColor: "lightsteelblue",
      featureMessageColor: "azure",
      enterColor: "greenyellow",
      leaveColor: "peachpuff",
      chatFromGirlColor: "lightskyblue",
      chatFromUserColor1: "lightskyblue",
      chatFromUserColor2: "sand",
      chatFromUserColor3: "beige",
      featureRequestColor: "lightpink",
      featureAcceptedColor: "lightgreen",
      featureDeniedColor: "lightred",
      secondBackgroundColor: "rgb(33, 47, 69)",
    },
    newstandard: {
      mainBackgroundColor: "#1A1A2E",
      navBarColor: "#16213E",
      mainTextColor: "#EAEAEA",
      secondTextColor: "#000",
      chatBackgroundColor: "#212F45",
      chatTextColor: "#EAEAEA",
      userListBackgroundColor: "#212F45",
      userListTextColor: "#EAEAEA",
      sysMessageColor: "lightsteelblue",
      featureMessageColor: "#00ADB5",
      enterColor: "#21BF73",
      leaveColor: "#F08A5D",
      chatFromGirlColor: "#5BC0F8",
      chatFromUserColor1: "#6A67CE",
      chatFromUserColor2: "#FFB400",
      chatFromUserColor3: "#F7D08A",
      featureRequestColor: "#F38181",
      featureAcceptedColor: "#7DDF64",
      featureDeniedColor: "#FF5C5C",
      secondBackgroundColor: "rgb(33, 47, 69)",
    },
  };
  